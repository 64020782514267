import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

let baseURL: string = '';
export const redirectURI: string = '/lion_home';

export const isLocalEnv =
  process.env.REACT_APP_API_LOCAL_BASE_URL?.includes('localhost');

if (process.env.REACT_APP_API_LOCAL_BASE_URL) {
  baseURL = process.env.REACT_APP_API_LOCAL_BASE_URL;
}

if (!baseURL) {
  console.log(`ERROR: no baseURL set, exiting`);
  process.exit(1);
}

export const client = axios.create({
  baseURL: baseURL,
});
