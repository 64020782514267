import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend from './CustomLegend';
import { ChartOptions } from 'chart.js';
import { downloadImage,downloadXLS } from 'utils/download'

interface CostVsExpectCostProps {
  data: Record<string, {
    cost: number;
    expected_cost: number;
  }>;
}

const CostVsExpectCost: React.FC<CostVsExpectCostProps> = ({ data }) => {
  // Create date range labels
  const labelsDict: Record<string, string> = {};
  Object.entries(data).forEach(([key, value]) => {
    const newKey = key.slice(9, 19);
    labelsDict[newKey] = `${key}`;
  });

  const labels = Object.keys(data).map(dateRange => dateRange.slice(9, 19));
  const costValues = Object.values(data).map(item => item.cost);
  const expectedCostValues = Object.values(data).map(item => item.expected_cost);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({
    Cost: true,
    'Expected Cost': true,
  });

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLegendClick = (label: string) => {
    setVisibility((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const revenueOverviewData = {
    labels: labels,
    datasets: [
      {
        label: 'Cost',
        data: costValues,
        borderColor: 'rgba(50, 215, 111, 0.80)',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: 'rgba(50, 215, 111, 0.80)',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility.Cost,
      },
      {
        label: 'Expected Cost',
        data: expectedCostValues,
        borderColor: '#595959',
        borderWidth: 1.5,
        fill: false,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#595959',
        pointRadius: 2,
        tension: 0.4,
        hidden: !visibility['Expected Cost'],
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const weekLabel = labels[tooltipItems[0].dataIndex];
            return `${labelsDict[weekLabel]}`;
          },
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Week',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cost',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
          stepSize: 5000,
          callback: function (value) {
            const numericValue = Number(value);
            return numericValue >= 1000 ? (numericValue / 1000) + 'K' : numericValue;
          },
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-light rounded-2xl shadow-sm mt-8" id="autoOptimizeCostVsExpectation">
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Cost vs Expected Cost</h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">Understand the impact of Auto Optimize on your Paid Search cost</h3>
        </div>
        <div className="relative" ref={dropdownRef} id='downloadDropdownMenu-autoOptimizeCostVsExpectation'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadXLS('autoOptimizeCostVsExpectation',['date','cost','expected cost'],revenueOverviewData.labels, revenueOverviewData.datasets.map(item => item.data) ) }}>Download XLS</li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadImage('autoOptimizeCostVsExpectation'); }}>Download PNG image</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        {/* <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[75%] md:w-[65%] w-[50%] h-[295px]"> */}
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[85%] xl:w-[85%] md:w-[85%] w-[85%] h-[295px]">
          <Line data={revenueOverviewData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 2xl:w-[15%] xl:w-[15%] md:w-[15%] w-[15%]">
          <div className="flex items-center">
            <CustomLegend legendSet="set3" onLegendClick={handleLegendClick} visibility={visibility} />
            <div className="mb-8 group inline-block -mt-4 2xl:mt-0 right-5 2xl:right-12 relative">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-[90%] mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Actual Paid Search spend</p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-[90%] transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div>
            <div className="group inline-block mt-5 right-1 relative">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute top-full left-1/2 transform -translate-x-[90%] mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Forecasted spend based<br></br>
                  on historical data</p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-[90%] transform -translate-x-1/2 -top-[6px] rotate-180" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostVsExpectCost;
