import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import CustomLegend2 from './CustomLegend2';
import { ChartOptions } from 'chart.js';
import ChangeLogB from './ChangeLogB';
import { ChangeLogData } from '../AutoOptimizePage';
import Dropdown from '../Dropdown';
import { downloadImage,downloadXLS } from 'utils/download'

type ChangeLogItem = {
  keyword: string;
  on_count: number;
  off_count: number;
};

type ChangeLog = {
  [date: string]: ChangeLogItem[];
};

const ChangeLog: React.FC<ChangeLogData> = ({ change_log, change_log_daily, change_log_summary }) => {
  function collectUniqueKeywords(changeLog: ChangeLog): Set<string> {
    const availableKeywords = new Set<string>();
    availableKeywords.add('All Negative Keywords');
    for (const entries of Object.values(changeLog)) {
      for (const entry of entries) {
        availableKeywords.add(entry.keyword);
      }
    }
    return availableKeywords;
  }
  const availableKeywords = Array.from(collectUniqueKeywords(change_log));

  const [selectedKeyword, setSelectedKeyword] = useState('All Negative Keywords');
  const [labels, setLabels] = useState<string[]>([]);
  const [on_count, setOnCount] = useState<number[]>([]);
  const [off_count, setOffCount] = useState<number[]>([]);

  const aggregateDailyData = (changeLog: ChangeLog, keyword: string): { [date: string]: { on_count: number; off_count: number } } => {
    const dailyData: { [date: string]: { on_count: number; off_count: number } } = {};
  
    for (const date in changeLog) {
      changeLog[date].forEach(item => {
        if (item.keyword == keyword) {
          if (!dailyData[date]) {
            dailyData[date] = { on_count: 0, off_count: 0 };
          }
          dailyData[date].on_count += item.on_count;
          dailyData[date].off_count += item.off_count;
        }
      });
    }
    return dailyData;
  };

  const newXlsDates: string[] = [];
  const newXlsKeywords: string[] = [];
  const newXlsOnCounts: number[] = [];
  const newXlsOffCounts: number[] = [];

  Object.entries(change_log).forEach(([date, items]) => {
    items.forEach(item => {
      newXlsDates.push(date); 
      newXlsKeywords.push(item.keyword); 
      newXlsOnCounts.push(item.on_count); 
      newXlsOffCounts.push(item.off_count); 
    });
  });

  useEffect(() => {
    const newLabels: string[] = [];
    const newOnCount: number[] = [];
    const newOffCount: number[] = [];

    if (selectedKeyword === 'All Negative Keywords') {
      newLabels.push(...Object.keys(change_log_daily));
      for (const date in change_log_daily) {
        const onCount = change_log_daily[date].on_count;
        const offCount = change_log_daily[date].off_count;
        const totalCount = onCount + offCount;

        newOnCount.push(onCount);
        newOffCount.push(offCount);           
      }
    } else {
      const dailyData = aggregateDailyData(change_log, selectedKeyword);

      const sortedDates = Object.keys(dailyData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      newLabels.push(...sortedDates);
      for (const date in dailyData) {
        const onCount = dailyData[date].on_count;
        const offCount = dailyData[date].off_count;
        const totalCount = onCount + offCount;

        newOnCount.push(onCount);
        newOffCount.push(offCount);        
      }
    }

    setLabels(newLabels);
    setOnCount(newOnCount);
    setOffCount(newOffCount);

  }, [selectedKeyword, change_log, change_log_daily]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const revenuePerCategoryData = {
    labels: labels,
    datasets: [
      {
        label: 'Negative Removed',
        data: on_count,
        backgroundColor: 'rgba(34, 34, 34, 0.80)',
        borderWidth: 0,
        borderRadius: 0,
        stack: 'stack0',
      },
      {
        label: 'Negative Applied',
        data: off_count,
        backgroundColor: 'rgba(144, 144, 144, 0.80)',
        borderWidth: 0,
        borderRadius: 0,
        stack: 'stack0',
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Daily Count',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
        },
        ticks: {
          display: true,
          color: '#333',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans'
          },
          align: 'center',
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
        max: undefined,
      },
    },
    indexAxis: 'x',
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-light rounded-2xl shadow-sm mt-8 relative" id='autoOptimizeChangeLog'>
      <div className="flex justify-between items-center border-b border-success mb-2">
        <div>
          <h2 className="text-primarygray text-2xl font-bold mb-1 pl-4 pt-2">Change Log</h2>
          <h3 className="text-gray800 text-sm font-bold mb-2 pl-4">Monitor the number of times a negative keyword within Auto Optimize was applied or removed</h3>
        </div>
        <div className="relative" ref={dropdownRef} id='downloadDropdownMenu-autoOptimizeChangeLog'>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-4 mt-2 w-48 bg-light text-sm text-gray700 rounded-md z-50" style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}>
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadXLS('autoOptimizeChangeLog',['date','keyword','on','off'],newXlsDates, [newXlsKeywords,newXlsOnCounts,newXlsOffCounts] )}}>Download XLS</li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => { closeDropdown(); downloadImage('autoOptimizeChangeLog'); }}>Download PNG image</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row items-start">
        {/* <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[75%] md:w-[65%] w-[50%] h-[295px]"> */}
        <div className="flex relative pl-2 py-6 justify-center items-center md:h-[295px] 2xl:w-[80%] xl:w-[80%] md:w-[80%] w-[80%] h-[295px]">
          <Bar data={revenuePerCategoryData} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6 ">
          <div className="flex items-center">
            <CustomLegend2 legendSet="set1" />
            {/* <div className="mb-8 group inline-block -right-2.5 relative">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Negative Keyword Remove</p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]" />
              </span>
            </div>
            <div className="group inline-block mt-5 right-1 relative">
              <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
              <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                <p className='px-4 py-2'>Negative Keyword Applied</p>
                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180" />
              </span>
            </div> */}
          </div>

          <div className="mt-12 text-sm font-bold flex flex-col items-start">
            {/* <select className="mb-2 bg-light border border-gray300 rounded-lg py-[11px] w-[164px] pl-3 text-thirdgray">
              <option className='text-left'>Account</option>
            </select> */}
            <Dropdown title={`${selectedKeyword}`} options={availableKeywords} onOptionClick={setSelectedKeyword} dropdownLength='max-h-48'/>
          </div>
        </div>
      </div>
      <div className=''>
        <ChangeLogB data={change_log_summary} />
      </div>
    </div>
  );
};

export default ChangeLog;
