import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';
import RecommendationConditions from './RecommendationConditions';
import RecommendationIndicator from './RecommendationIndicators';

import { RecommendationConditionsData } from './RecommendationConditions';
import { RecommendationIndicatorsData } from './RecommendationIndicators';

interface ConfigurationProps {
  isOpen: boolean;
  onClose: () => void;
}

const fetchInsightsConfigurationData = async () => {
  const response = await fetch(`/get_insights_configuration_data/`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const Configuration: React.FC<ConfigurationProps> = ({ isOpen, onClose }) => {
  const [indicatorData, setIndicatorData] = useState<RecommendationIndicatorsData>();
  const [conditionData, setConditionData] = useState<RecommendationConditionsData>();

  const { data, error, isLoading } = useQuery(['insightsConfiguration'], () => fetchInsightsConfigurationData());
  
  useEffect(() => {
    if (data) {
      if (data.recommendation_indicators) {
        setIndicatorData(data.recommendation_indicators)
      }
      if (data.recommendation_conditions) { 
        setConditionData(data.recommendation_conditions)
      }

      if (Object.keys(data.recommendation_indicators).length === 0) {
        setIndicatorData({
          organicGoodPositionThreshold: 0,
          organicHighClicksRatio: 0,
          organicHighCtrThreshold: 0,
          organicHighImpressionsRatio: 0,
          organicTopPositionThreshold: 0,
          paidHasCost: 0,
          paidHighCompetitionThreshold: 0,
          paidHighCpcThreshold: 0,
          paidHighCtrThreshold: 0,
          paidLowCompetitionThreshold: 0,
          paidLowCvrThreshold: 0,
          paidLowQualityThreshold: 0,
          paidNoCompetitionThreshold: 0,
        });
      }
    }
  }, [data]);

  if (!isOpen) {
    return null;
  }
  return (
    <div>
      <div>
        {indicatorData ? (<RecommendationIndicator onClose={onClose} data={indicatorData}/>) : (<LoadingSpinner />)}
      </div>
      <div>
        {conditionData ? (<RecommendationConditions onClose={onClose} data={conditionData}/>) : (<LoadingSpinner />)}
      </div>
    </div>
  );
};

export default Configuration;
