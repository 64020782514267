import { DateType, DateValueType } from 'react-tailwindcss-datepicker';

export const formatDateRange = (dateRange: DateValueType) => {
  let startDate: DateType = dateRange?.startDate || null;
  let endDate: DateType = dateRange?.endDate || null;

  if (typeof dateRange?.startDate === 'string') {
    const temp = dateRange.startDate.split('-').map((val) => parseInt(val));
    startDate = new Date(temp[0], temp[1] - 1, temp[2]);
  }

  if (typeof dateRange?.endDate === 'string') {
    const temp = dateRange.endDate.split('-').map((val) => parseInt(val));
    endDate = new Date(temp[0], temp[1] - 1, temp[2]);
  }

  return {
    startDate,
    endDate,
  };
};
