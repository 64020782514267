import React, { FC, useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions, ChartData, ChartDataset } from 'chart.js';
import 'chart.js/auto';
import CustomLegend from '../CustomLegend';

import { OverviewProps } from '../Overview';

// Define dataset type
interface Dataset {
  type: 'bar';
  label: string;
  data: number[];
  backgroundColor: string;
  borderWidth: number;
  borderRadius: number;
  barThickness: number;
  category: string;
}

// Define the dataSets type
interface DataSets {
  Coverage: Dataset[];
  Rank: Dataset[];
  '# Search Terms': Dataset[];
}

const OverviewChart: FC<OverviewProps> = ({ data, device, activeTab, selectedFilters, competitorsToBeIncluded }) => {
  const [chartParameters, setChartParameters] = useState<ChartData<'bar'>>({
    labels: [],
    datasets: []
  });

  const chartRef = useRef(null);
  const chartWidth = (chartRef.current as HTMLElement | null)?.offsetWidth || 500;
  const numberOfCompetitorsDesktop = Object.keys(data['desktop']["current"]["PAID"]["coverage"]).length + Object.keys(data['desktop']["current"]["ORGANIC"]["coverage"]).length + Object.keys(data['desktop']["current"]["SHOPPING"]["coverage"]).length;
  const numberOfCompetitorsMobile = Object.keys(data['mobile']["current"]["PAID"]["coverage"]).length + Object.keys(data['mobile']["current"]["ORGANIC"]["coverage"]).length + Object.keys(data['mobile']["current"]["SHOPPING"]["coverage"]).length;
  const calculatedBarThicknessDesktop = Math.max(10, chartWidth / numberOfCompetitorsDesktop); 
  const calculatedBarThicknessMobile = Math.max(10, chartWidth / numberOfCompetitorsMobile);

  useEffect(() => {
    let dataSets: DataSets;
    let labels: string[] = [];
    if (device === 'desktop') {
      dataSets = {
        Coverage: [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['desktop']["previous"]["PAID"]["coverage"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['desktop']["current"]["PAID"]["coverage"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['desktop']["current"]["ORGANIC"]["coverage"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['desktop']["previous"]["ORGANIC"]["coverage"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['desktop']["previous"]["SHOPPING"]["coverage"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['desktop']["current"]["SHOPPING"]["coverage"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
        ],
        Rank: [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['desktop']["previous"]["PAID"]["rank"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['desktop']["current"]["PAID"]["rank"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['desktop']["previous"]["ORGANIC"]["rank"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['desktop']["current"]["ORGANIC"]["rank"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['desktop']["previous"]["SHOPPING"]["rank"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['desktop']["current"]["SHOPPING"]["rank"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
        ],
        '# Search Terms': [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['desktop']["previous"]["PAID"]["search_terms"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['desktop']["current"]["PAID"]["search_terms"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['desktop']["previous"]["ORGANIC"]["search_terms"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['desktop']["current"]["ORGANIC"]["search_terms"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['desktop']["previous"]["SHOPPING"]["search_terms"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['desktop']["current"]["SHOPPING"]["search_terms"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessDesktop,
            category: 'SHOPPING',
          },
        ],
      };      
    } else if (device === 'mobile') {
      dataSets = {
        Coverage: [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['mobile']["previous"]["PAID"]["coverage"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['mobile']["current"]["PAID"]["coverage"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['mobile']["current"]["ORGANIC"]["coverage"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['mobile']["previous"]["ORGANIC"]["coverage"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['mobile']["previous"]["SHOPPING"]["coverage"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['mobile']["current"]["SHOPPING"]["coverage"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
        ],
        Rank: [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['mobile']["previous"]["PAID"]["rank"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['mobile']["current"]["PAID"]["rank"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['mobile']["previous"]["ORGANIC"]["rank"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['mobile']["current"]["ORGANIC"]["rank"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['mobile']["previous"]["SHOPPING"]["rank"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['mobile']["current"]["SHOPPING"]["rank"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
        ],
        '# Search Terms': [
          {
            type: 'bar',
            label: 'Prior PAID',
            data: Object.values(data['mobile']["previous"]["PAID"]["search_terms"]),
            backgroundColor: 'rgba(144, 144, 144, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Current PAID',
            data: Object.values(data['mobile']["current"]["PAID"]["search_terms"]),
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'PAID',
          },
          {
            type: 'bar',
            label: 'Prior ORGANIC',
            data: Object.values(data['mobile']["previous"]["ORGANIC"]["search_terms"]),
            backgroundColor: 'rgba(234, 251, 240, 1)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Current ORGANIC',
            data: Object.values(data['mobile']["current"]["ORGANIC"]["search_terms"]),
            backgroundColor: 'rgba(50, 215, 111, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'ORGANIC',
          },
          {
            type: 'bar',
            label: 'Prior SHOPPING',
            data: Object.values(data['mobile']["previous"]["SHOPPING"]["search_terms"]),
            backgroundColor: 'rgba(3, 197, 255, 0.2)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
          {
            type: 'bar',
            label: 'Current SHOPPING',
            data: Object.values(data['mobile']["current"]["SHOPPING"]["search_terms"]),
            backgroundColor: 'rgba(3, 197, 255, 0.8)',
            borderWidth: 1,
            borderRadius: 8,
            barThickness: calculatedBarThicknessMobile,
            category: 'SHOPPING',
          },
        ],
      };  
    }  else {
      throw new Error('Invalid device type');
    }
  
    const filteredDatasets = dataSets[activeTab as keyof typeof dataSets].filter((dataset: Dataset) =>
      selectedFilters.includes(dataset.category)
    );

    // Determine the category (PAID, ORGANIC, or SHOPPING) based on selected filters
    const activeCategory = selectedFilters.includes('PAID') ? 'PAID' :
    selectedFilters.includes('ORGANIC') ? 'ORGANIC' : 'SHOPPING';

    // Dynamically determine labels by merging current and previous labels based on the active tab
    if (device && activeTab && activeCategory) {
    const currentLabels = Object.keys(data[device]["current"][activeCategory][
    activeTab === 'Coverage' ? 'coverage' :
    activeTab === 'Rank' ? 'rank' : 'search_terms'
    ]);

    const previousLabels = Object.keys(data[device]["previous"][activeCategory][
    activeTab === 'Coverage' ? 'coverage' :
    activeTab === 'Rank' ? 'rank' : 'search_terms'
    ]);

    // Merge current and previous labels and remove duplicates using Set
    labels = Array.from(new Set([...currentLabels, ...previousLabels]));
    }

    // Convert filtered datasets to Chart.js dataset format
    const chartDatasets: ChartDataset<'bar', number[]>[] = filteredDatasets.map(dataset => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: dataset.backgroundColor,
      borderWidth: dataset.borderWidth,
      borderRadius: dataset.borderRadius,
      barThickness: dataset.barThickness,
    }));

    setChartParameters({ labels, datasets: chartDatasets });

  }, [data, device, selectedFilters, activeTab]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Competitors',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          color: '#666',
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          maxRotation: 90,
          minRotation:45,
        },
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
      y: {
        title: {
          display: true,
          text: activeTab === 'Coverage' ? 'Coverage (%)' : activeTab === 'Rank' ? 'Rank' : '# Search Terms',
          color: '#4D4D4D',
          font: {
            size: 14,
            style: 'normal',
            weight: 700,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
        },
        ticks: {
          display: true,
          color: '#000',
          stepSize: activeTab === 'Coverage' ? 25 : 1,
          callback: function (value) {
            return activeTab === 'Coverage' ? value + '%' : value;
          },
          font: {
            size: 14,
            style: 'normal',
            weight: 500,
            lineHeight: 1.28,
            family: 'DM Sans',
          },
          align: 'center',
        },
        min: 0,
        // max: activeTab === 'Coverage' ? 100 : activeTab === 'Rank' ? 60 : activeTab === '# Search Terms' ? 100 : 100,
        grid: {
          color: '#B3B3B3',
        },
        border: {
          color: '#333333',
          display: true,
        },
      },
    },
  };
  
  return (
    <div className="">
      <div className="flex">
        <div className="flex relative px-2 py-6 justify-center items-center md:h-[495px] 2xl:w-[calc(92%-200px)] md:w-[65%] w-[60%] h-[495px]">
          <Bar data={chartParameters} options={options} />
        </div>
        <div className="flex-none text-xs flex flex-col items-start pl-4 pr-4 py-6">
          <CustomLegend legendSet="set3" />
        </div>
      </div>
    </div>
  );
};

export default OverviewChart;