import moment from 'moment';
import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { downloadImage, downloadXLS } from 'utils/download';

// COMPONENTS
import MultiDropdown from '../MultiDropdown';
import LoadingSpinner from '../LoadingSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import OverviewChart from './OVComponents/OverviewChart';
import DatepickerComponent from '../DatepickerComponent';
import { DateValueType } from 'react-tailwindcss-datepicker';
import OverviewLineChart from './OVComponents/OverviewLineChart';
import TimeFramePicker, { TimeFrameDateRange } from '../TimeFramePicker';

interface MetricInnerData {
  [key: string]: number;
}

interface coverageCompInner {
  [brand: string]: {
    [ad_type: string]: {
      [date: string]: {
        rank: number;
        coverage: number;
        search_terms_count: number;
      };
    };
  };
}

export interface MetricData {
  coverage: { [key: string]: number };
  rank: { [key: string]: number };
  search_terms: { [key: string]: number };
}

interface CompetitorGroup {
  group_id: number;
  group_name: string;
  domains: string;
}

interface CompetitiveIntelligenceData {
  competitor_groups: CompetitorGroup[];
}

type OverviewDataSection = {
  mobile: {
    current: {
      PAID: MetricData;
      ORGANIC: MetricData;
      SHOPPING: MetricData;
    };
    previous: {
      PAID: MetricData;
      ORGANIC: MetricData;
      SHOPPING: MetricData;
    };
    coverageComp: {
      [brand: string]: {
        [ad_type: string]: {
          [date: string]: {
            rank: number;
            coverage: number;
            search_terms_count: number;
          };
        };
      };
    };
  };
  desktop: {
    current: {
      PAID: MetricData;
      ORGANIC: MetricData;
      SHOPPING: MetricData;
    };
    previous: {
      PAID: MetricData;
      ORGANIC: MetricData;
      SHOPPING: MetricData;
    };
    coverageComp: {
      [brand: string]: {
        [ad_type: string]: {
          [date: string]: {
            rank: number;
            coverage: number;
            search_terms_count: number;
          };
        };
      };
    };
  };
  unique_brands: string[];
};

export interface OverviewProps {
  device: string;
  activeTab: string;
  selectedFilters: string[];
  competitorsToBeIncluded: string[];
  data: OverviewDataSection;
}

// FETCH COMP. INTEL OVERVIEW DATA
const fetchCompData = async (dateRange: TimeFrameDateRange | null = null) => {
  if (!dateRange || !dateRange.endDate || !dateRange.startDate) return;
  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');

  const response = await fetch(
    `/get_comp_data/overview/${startDate}/${endDate}`
  );
  // const response = await fetch(`/get_comp_data/overview/2024-06-05/2024-06-12`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

// TOOLTIPS
const tooltipTexts = {
  Coverage: [
    'Percent of time a brand appeared on a search',
    'term/number of times the keyword was',
    'queried during a selected time period',
  ],
  Rank: [
    'Average of all positions for a given',
    'advertiser/total number of positions',
    'possible (or results displayed) when seen',
  ],
  '# Search Terms': [
    'Search terms tracked on the SERP with',
    'chosen engine, location, language, and',
    'study type assignment',
  ],
};

// TAB OPTIONS
const tabs: ('Coverage' | 'Rank' | '# Search Terms')[] = [
  'Coverage',
  'Rank',
  '# Search Terms',
];

const fetchCompetitorGroups = async (): Promise<{
  competitorGroups: CompetitorGroup[];
  allDomains: string[];
}> => {
  const response = await fetch('/competitor-groups-list/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const compGroupData: CompetitiveIntelligenceData = await response.json();
  if (compGroupData.competitor_groups.length === 0) {
    return { competitorGroups: [], allDomains: [] };
  }
  const competitorGroups = compGroupData.competitor_groups;
  const allDomains = competitorGroups
    .map((group) => group.domains.split(','))
    .flat();

  return { competitorGroups, allDomains };
};

// COMP. INTEL OVERVIEW COMPONENT
function CompIntelOverview() {
  const clientDomain = useOutletContext<string>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<string>('Desktop');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });

  const [competitorGroupOptions, setCompetitorGroupOptions] = useState<
    CompetitorGroup[]
  >([]);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState<string[]>(
    []
  );
  const [competitorOptions, setCompetitorOptions] = useState<string[]>([]);
  const [preselectedCompetitors, setPreselectedCompetitors] = useState<
    string[]
  >([]);

  const defaultDataStructure: OverviewDataSection = {
    desktop: {
      coverageComp: {},
      current: {
        ORGANIC: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        PAID: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        SHOPPING: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
      },
      previous: {
        ORGANIC: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        PAID: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        SHOPPING: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
      },
    },
    mobile: {
      coverageComp: {},
      current: {
        ORGANIC: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        PAID: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        SHOPPING: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
      },
      previous: {
        ORGANIC: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        PAID: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
        SHOPPING: {
          coverage: {},
          rank: {},
          search_terms: {},
        },
      },
    },
    unique_brands: [],
  };
  const [filteredData, setFilteredData] =
    useState<OverviewDataSection>(defaultDataStructure);

  const { data: compGroupsData, isLoading: isLoadingCompGroups } = useQuery(
    ['competitorGroupsData'],
    () => fetchCompetitorGroups(),
    {}
  );

  const { data: compData, isLoading: isLoadingCompData } = useQuery(
    ['compDataOverview', dateRange?.startDate, dateRange?.endDate],
    () => fetchCompData(dateRange),
    { enabled: !!dateRange }
  );
  useEffect(() => {
    if (
      compGroupsData &&
      !isLoadingCompGroups &&
      compData &&
      !isLoadingCompData
    ) {
      const { competitorGroups, allDomains } = compGroupsData;

      setCompetitorGroupOptions(competitorGroups);
      setCompetitorOptions([...allDomains]);

      if (preselectedCompetitors.length === 0) {
        setPreselectedCompetitors([...allDomains]);
      } else if (!preselectedCompetitors.includes(clientDomain)) {
        setPreselectedCompetitors([clientDomain, ...allDomains]);
      } else if (
        preselectedCompetitors.includes(clientDomain) &&
        preselectedCompetitors.length === 1
      ) {
        setPreselectedCompetitors([...allDomains]);
      }

      if (clientDomain.length === 0 && compData) {
        const topCompetitors = getTopCompetitorsByCoverage(compData, 10);
        setPreselectedCompetitors(topCompetitors);
      }

      const initialSelectedGroups = competitorGroups.map(
        (group) => `${group.group_name}`
      );
      setSelectedGroupOptions(initialSelectedGroups);
    }
  }, [
    compGroupsData,
    isLoadingCompGroups,
    compData,
    isLoadingCompData,
    clientDomain,
  ]);

  // Function to get the top N competitors by coverage
  const getTopCompetitorsByCoverage = (
    data: OverviewDataSection,
    limit: number
  ): string[] => {
    const allCompetitors = Object.keys(data.desktop.coverageComp);

    const sortedCompetitors = allCompetitors
      .map((competitor) => {
        const totalCoverage = Object.values(
          data.desktop.coverageComp[competitor]
        ).reduce((sum, adTypeData) => {
          return (
            sum +
            Object.values(adTypeData).reduce(
              (acc, { coverage }) => acc + coverage,
              0
            )
          );
        }, 0);
        return { competitor, totalCoverage };
      })
      .sort((a, b) => b.totalCoverage - a.totalCoverage)
      .slice(0, limit)
      .map(({ competitor }) => competitor);

    return sortedCompetitors;
  };

  useEffect(() => {
    if (clientDomain && !preselectedCompetitors.includes(clientDomain)) {
      setPreselectedCompetitors((prev) => [...prev, clientDomain]);
    }
  }, [clientDomain, preselectedCompetitors]);

  const handleCompetitorChange = (competitor: string) => {
    setPreselectedCompetitors((prev) => {
      if (prev.includes(competitor)) {
        return prev.filter((item) => item !== competitor);
      } else {
        return [...prev, competitor];
      }
    });
  };

  const handleCompetitorGroupApply = (groups: string[]) => {
    let selectedCompetitors: string[] = [];
    let selectedGroups: string[] = [];

    groups.forEach((group_name) => {
      const matchedGroup = competitorGroupOptions.find(
        (g) => g.group_name === group_name
      );
      if (matchedGroup) {
        selectedCompetitors = [
          ...selectedCompetitors,
          ...matchedGroup.domains.split(','),
        ];
        selectedGroups = [...selectedGroups, ...[group_name]];
      }
    });

    setPreselectedCompetitors(selectedCompetitors);
    setSelectedGroupOptions(selectedGroups);
  };

  const [activeTab, setActiveTab] = useState<
    'Coverage' | 'Rank' | '# Search Terms'
  >('Coverage');
  const [selectedFilters, setSelectedFilters] = useState<string[]>(['PAID']);
  const { data, error, isLoading } = useQuery(
    ['compDataOverview', dateRange?.startDate, dateRange?.endDate],
    () => fetchCompData(dateRange),
    { enabled: !!dateRange }
  );

  useEffect(() => {
    setCompetitorOptions(
      Array.from(
        new Set([
          ...(data?.unique_brands || []),
          ...(compGroupsData?.allDomains || []),
        ])
      )
    );
  }, [data, compGroupsData]);

  useEffect(() => {
    if (preselectedCompetitors.length > 0) {
      if (data) {
        const newData = filterDataBasedOnDropdown(data, preselectedCompetitors);
        setFilteredData(newData);
      }
    } else {
      setFilteredData(defaultDataStructure);
    }
  }, [data, preselectedCompetitors]);

  // HANDLE FILTER TOGGLE (updated to support single selection)
  const toggleFilter = (filter: string) => {
    setSelectedFilters([filter]); // Replace with the newly selected filter
  };

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (timeFrame: TimeFrameDateRange) => {
    setDateRange(timeFrame);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  // DROWDOWN (IS THIS STILL NEEDED?)
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const downloadChartImage = () => {
    downloadImage('CompetitiveIntelligenceOverview');
  };

  // -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  const filterDomainData = (
    data: MetricInnerData,
    selectedDomains: string[]
  ) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as MetricInnerData);
  };

  const filterCoverageComp = (
    coverageComp: coverageCompInner,
    selectedDomains: string[]
  ): coverageCompInner => {
    return Object.keys(coverageComp)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = coverageComp[domain];
        return acc;
      }, {} as coverageCompInner);
  };

  const unifyCompetitorsData = (
    currentData: MetricInnerData,
    previousData: MetricInnerData
  ) => {
    // Get all unique competitors from both current and previous data
    const allCompetitors = Array.from(
      new Set([...Object.keys(currentData), ...Object.keys(previousData)])
    );

    // Ensure both datasets have the same competitors, initializing missing competitors with 0
    const unifiedCurrentData = allCompetitors.reduce((acc, competitor) => {
      acc[competitor] =
        currentData[competitor] !== undefined ? currentData[competitor] : 0;
      return acc;
    }, {} as MetricInnerData);

    const unifiedPreviousData = allCompetitors.reduce((acc, competitor) => {
      acc[competitor] =
        previousData[competitor] !== undefined ? previousData[competitor] : 0;
      return acc;
    }, {} as MetricInnerData);

    return { unifiedCurrentData, unifiedPreviousData };
  };

  const filterDataBasedOnDropdown = (
    data: OverviewDataSection,
    selectedDomains: string[]
  ): OverviewDataSection => {
    if (selectedDomains.length === 0) return defaultDataStructure;

    const newData: OverviewDataSection = {
      desktop: {
        coverageComp: {},
        current: {
          ORGANIC: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          PAID: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          SHOPPING: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
        },
        previous: {
          ORGANIC: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          PAID: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          SHOPPING: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
        },
      },
      mobile: {
        coverageComp: {},
        current: {
          ORGANIC: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          PAID: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          SHOPPING: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
        },
        previous: {
          ORGANIC: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          PAID: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
          SHOPPING: {
            coverage: {},
            rank: {},
            search_terms: {},
          },
        },
      },
      unique_brands: [],
    };

    // Filter dataset
    newData.desktop.coverageComp = filterCoverageComp(
      data.desktop.coverageComp ?? {},
      selectedDomains
    );
    newData.mobile.coverageComp = filterCoverageComp(
      data.mobile.coverageComp ?? {},
      selectedDomains
    );

    // Filter desktop data
    (['ORGANIC', 'PAID', 'SHOPPING'] as const).forEach((filterKey) => {
      const currentCoverage = filterDomainData(
        data.desktop.current[filterKey]?.coverage ?? {},
        selectedDomains
      );
      const previousCoverage = filterDomainData(
        data.desktop.previous[filterKey]?.coverage ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedCoverageCurrent,
        unifiedPreviousData: unifiedCoveragePrevious,
      } = unifyCompetitorsData(currentCoverage, previousCoverage);

      newData.desktop.current[filterKey].coverage = unifiedCoverageCurrent;
      newData.desktop.previous[filterKey].coverage = unifiedCoveragePrevious;

      const currentRank = filterDomainData(
        data.desktop.current[filterKey]?.rank ?? {},
        selectedDomains
      );
      const previousRank = filterDomainData(
        data.desktop.previous[filterKey]?.rank ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedRankCurrent,
        unifiedPreviousData: unifiedRankPrevious,
      } = unifyCompetitorsData(currentRank, previousRank);

      newData.desktop.current[filterKey].rank = unifiedRankCurrent;
      newData.desktop.previous[filterKey].rank = unifiedRankPrevious;

      const currentSearchTerms = filterDomainData(
        data.desktop.current[filterKey]?.search_terms ?? {},
        selectedDomains
      );
      const previousSearchTerms = filterDomainData(
        data.desktop.previous[filterKey]?.search_terms ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedSearchTermsCurrent,
        unifiedPreviousData: unifiedSearchTermsPrevious,
      } = unifyCompetitorsData(currentSearchTerms, previousSearchTerms);

      newData.desktop.current[filterKey].search_terms =
        unifiedSearchTermsCurrent;
      newData.desktop.previous[filterKey].search_terms =
        unifiedSearchTermsPrevious;
    });

    // Filter mobile data
    (['ORGANIC', 'PAID', 'SHOPPING'] as const).forEach((filterKey) => {
      const currentCoverage = filterDomainData(
        data.mobile.current[filterKey]?.coverage ?? {},
        selectedDomains
      );
      const previousCoverage = filterDomainData(
        data.mobile.previous[filterKey]?.coverage ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedCoverageCurrent,
        unifiedPreviousData: unifiedCoveragePrevious,
      } = unifyCompetitorsData(currentCoverage, previousCoverage);

      newData.mobile.current[filterKey].coverage = unifiedCoverageCurrent;
      newData.mobile.previous[filterKey].coverage = unifiedCoveragePrevious;

      const currentRank = filterDomainData(
        data.mobile.current[filterKey]?.rank ?? {},
        selectedDomains
      );
      const previousRank = filterDomainData(
        data.mobile.previous[filterKey]?.rank ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedRankCurrent,
        unifiedPreviousData: unifiedRankPrevious,
      } = unifyCompetitorsData(currentRank, previousRank);

      newData.mobile.current[filterKey].rank = unifiedRankCurrent;
      newData.mobile.previous[filterKey].rank = unifiedRankPrevious;

      const currentSearchTerms = filterDomainData(
        data.mobile.current[filterKey]?.search_terms ?? {},
        selectedDomains
      );
      const previousSearchTerms = filterDomainData(
        data.mobile.previous[filterKey]?.search_terms ?? {},
        selectedDomains
      );

      const {
        unifiedCurrentData: unifiedSearchTermsCurrent,
        unifiedPreviousData: unifiedSearchTermsPrevious,
      } = unifyCompetitorsData(currentSearchTerms, previousSearchTerms);

      newData.mobile.current[filterKey].search_terms =
        unifiedSearchTermsCurrent;
      newData.mobile.previous[filterKey].search_terms =
        unifiedSearchTermsPrevious;
    });

    newData.unique_brands = data?.unique_brands ?? [];

    return newData;
  };

  // -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  const downloadChartData = () => {
    if (!filteredData) {
      return;
    }

    const selectedDeviceData =
      view === 'Desktop' ? filteredData.desktop : filteredData.mobile;
    const selectedFilter = selectedFilters[0] as
      | 'PAID'
      | 'ORGANIC'
      | 'SHOPPING';
    const activeTabKey =
      activeTab === 'Coverage'
        ? 'coverage'
        : activeTab === 'Rank'
        ? 'rank'
        : 'search_terms';

    // Dynamically access the data based on the selected filter and active tab
    const currentData =
      selectedDeviceData?.current?.[selectedFilter]?.[activeTabKey] || {};
    const priorData =
      selectedDeviceData?.previous?.[selectedFilter]?.[activeTabKey] || {};

    // Get all competitors from both current and prior data
    const allCompetitors = Array.from(
      new Set([...Object.keys(currentData), ...Object.keys(priorData)])
    );

    const headers = [
      'Competitor',
      `${selectedFilter}_${activeTabKey.toUpperCase()}_CURRENT`,
      `${selectedFilter}_${activeTabKey.toUpperCase()}_PRIOR`,
    ];

    // Prepare data for both current and prior values
    const labels = allCompetitors;

    // Map current and prior values for each competitor, using 0 if data is missing
    const currentValues = allCompetitors.map((competitor) =>
      currentData[competitor] !== undefined ? currentData[competitor] : '0'
    );
    const priorValues = allCompetitors.map((competitor) =>
      priorData[competitor] !== undefined ? priorData[competitor] : '0'
    );

    // Download the data in XLS format
    downloadXLS(
      `Competitive_${activeTabKey}_${selectedFilter}_${view}_Overview`,
      headers,
      labels,
      [currentValues, priorValues]
    );
  };

  const downloadCoverageCompData = () => {
    if (!filteredData) {
      return;
    }

    const selectedDeviceData =
      view === 'Desktop' ? filteredData.desktop : filteredData.mobile;

    const selectedFilter = selectedFilters[0] as
      | 'PAID'
      | 'ORGANIC'
      | 'SHOPPING';
    const activeTabKey =
      activeTab === 'Coverage'
        ? 'coverage'
        : activeTab === 'Rank'
        ? 'rank'
        : 'search_terms_count';

    if (
      !selectedDeviceData.current[selectedFilter] ||
      !selectedDeviceData.coverageComp
    ) {
      console.warn(
        'No data available for the selected filter or coverageComp.'
      );
      return;
    }
    const competitors = Object.keys(selectedDeviceData.coverageComp);

    if (competitors.length === 0) {
      return;
    }

    const headers = ['Competitor', 'Date', `${selectedFilter} ${activeTabKey}`];

    const labels: string[] = [];
    const columns: Array<Array<string | number>> = [[], []];

    competitors.forEach((competitor) => {
      if (selectedDeviceData.coverageComp[competitor][selectedFilter]) {
        const dates = Object.keys(
          selectedDeviceData.coverageComp[competitor][selectedFilter]
        );

        dates.forEach((date) => {
          const metricValue =
            selectedDeviceData.coverageComp[competitor][selectedFilter][date][
              activeTabKey
            ];

          labels.push(competitor); // Competitor
          columns[0].push(date); // Date
          columns[1].push(metricValue); // Metric (coverage, rank, or search_terms)
        });
      }
    });

    downloadXLS(
      `Competitive_${selectedFilter}_${activeTabKey}_${view}_TimeSeriesOverview`,
      headers,
      labels,
      columns
    );
  };

  // JSX
  return (
    <div className="py-12">
      <FilterBarWrapper>
        <TimeFramePicker onChange={onChangeTimeFrame} defaultValue="1M" />
        <div className="flex-1" />
        <div className="flex flex-wrap gap-2 mt-2 md:mt-0">
          <MultiDropdown
            placeholder={'Competitor Groups'}
            options={competitorGroupOptions.map(
              (group) => `${group.group_name}`
            )}
            preselectedOptions={selectedGroupOptions}
            // onOptionClick={handleCompetitorChange}
            onSelectionChange={handleCompetitorGroupApply}
            dropdownLength="max-h-60"
            dropdownOpenWidth="max-w-80"
          />
          <MultiDropdown
            placeholder={'Domains'}
            options={competitorOptions}
            preselectedOptions={preselectedCompetitors}
            // onOptionClick={handleCompetitorChange}
            onSelectionChange={setPreselectedCompetitors}
            dropdownLength="max-h-60"
            dropdownOpenWidth="max-w-80"
          />
          {/* <Dropdown title='Competitor' options={['competitor 1', 'competitor 2', 'competitor 3']} mode='light' onOptionClick={() => {}}/> */}
          <DatepickerComponent
            value={dateRange}
            onChange={onChangeDatePicker}
            product="comp_int"
          />
        </div>
      </FilterBarWrapper>
      <div className="flex justify-end mb-4 mr-4 text-xs text-gray300 font-normal">
        Data updates daily
      </div>

      {/* ---- PAGE CONTENT (CHARTS) ----- */}
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>Error fetching data</div>
      ) : (
        <div
          id="CompetitiveIntelligenceOverview"
          className="mb-4 bg-light border rounded-2xl py-2"
        >
          <div className="flex justify-between items-center mb-2 px-4">
            <div>
              <h2 className="text-2xl font-bold">Overview</h2>
              <p className="text-gray800 text-sm font-bold mt-[2px]">
                How the competition and market is evolving
              </p>
            </div>
            <div
              className="flex items-center"
              ref={dropdownRef}
              id="downloadDropdownMenu-CompetitiveIntelligenceOverview"
            >
              <div className="flex items-center mr-4 text-xs">
                <span
                  className={
                    view === 'Desktop'
                      ? 'text-primarygray mr-2'
                      : 'text-gray400 mr-2'
                  }
                >
                  Desktop
                </span>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={view === 'Mobile'}
                    onChange={() =>
                      setView(view === 'Desktop' ? 'Mobile' : 'Desktop')
                    }
                  />
                  <div className="relative w-10 h-5 bg-lightblue rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
                </label>
                <span
                  className={
                    view === 'Mobile'
                      ? 'text-primarygray ml-2'
                      : 'text-gray400 ml-2'
                  }
                >
                  Mobile
                </span>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
                alt="dots"
                className="h-6 w-6 ml-2 cursor-pointer"
                onClick={toggleDropdown}
              />
              {isDropdownOpen && (
                <div
                  className="absolute right-10 mt-40 w-48 bg-light text-sm text-gray700 rounded-md z-50"
                  style={{ boxShadow: '1px 1px 8px 0px rgba(0, 0, 0, 0.15)' }}
                >
                  <ul>
                    {/* <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={closeDropdown}
                    >
                      View data table
                    </li> */}
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        closeDropdown();
                        downloadChartData();
                        downloadCoverageCompData();
                      }}
                    >
                      Download XLS
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        downloadChartImage();
                        closeDropdown();
                      }}
                    >
                      Download PNG image
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <hr className="border-success mb-6" />
          <div className="flex flex-col lg:flex-row mb-4">
            <div className="flex justify-between items-center p-2 w-full px-4 whitespace-nowrap flex-wrap gap-4">
              <div className="flex space-x-2 bg-fourthgray py-1 px-1 rounded-lg">
                {tabs.map((tab) => (
                  <div key={tab} className="relative group inline-block">
                    <button
                      className={`${
                        activeTab === tab
                          ? 'px-4 py-[9px] rounded bg-light text-primarygray'
                          : 'text-thirdgray px-4 py-[9px]'
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </button>
                    {tab === 'Rank' ? (
                      <span className="absolute z-20 bottom-full left-1/2 transform -translate-x-1/2 mb-3 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                        <div className="px-4 py-2">
                          {tooltipTexts[tab].map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                          alt="arrow"
                          className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]"
                        />
                      </span>
                    ) : (
                      <span className="absolute z-20 top-full left-1/2 transform -translate-x-1/2 mt-3 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                        <div className="px-4 py-2">
                          {tooltipTexts[tab].map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                        <img
                          src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                          alt="arrow"
                          className="absolute z-20 left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180"
                        />
                      </span>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex space-x-1">
                {['PAID', 'ORGANIC', 'SHOPPING'].map((filter) => (
                  <span
                    key={filter}
                    className={`text-thirdgray rounded px-3 py-1 cursor-pointer ${
                      selectedFilters.includes(filter)
                        ? 'border border-success rounded'
                        : 'border border-disgreen100 rounded'
                    }`}
                    onClick={() => toggleFilter(filter)} // Call the updated toggleFilter
                  >
                    {filter}
                    {selectedFilters.includes(filter) && (
                      <span className="ml-1">✕</span>
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
          {data ? (
            <>
              {/* <OverviewChart
                data={data}
                device={view.toLowerCase()}
                activeTab={activeTab}
                selectedFilters={selectedFilters}
                competitorsToBeIncluded={preselectedCompetitors}
              /> */}
              {filteredData ? (
                <OverviewChart
                  data={filteredData}
                  device={view.toLowerCase()}
                  activeTab={activeTab}
                  selectedFilters={selectedFilters}
                  competitorsToBeIncluded={preselectedCompetitors}
                />
              ) : (
                <LoadingSpinner />
              )}

              {/* <OverviewLineChart
                data={data}
                device={view.toLowerCase()}
                activeTab={activeTab}
                selectedFilters={selectedFilters}
                competitorsToBeIncluded={preselectedCompetitors}
              /> */}
              {filteredData ? (
                <OverviewLineChart
                  data={filteredData}
                  device={view.toLowerCase()}
                  activeTab={activeTab}
                  selectedFilters={selectedFilters}
                  competitorsToBeIncluded={preselectedCompetitors}
                />
              ) : (
                <LoadingSpinner />
              )}
            </>
          ) : (
            <div>No data available.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CompIntelOverview;
