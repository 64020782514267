import React, { useState, useEffect, ReactNode } from 'react';
import Overview from './insights_components/Overview';
import Cluster from './insights_components/Cluster';
import Recommendations from './insights_components/Recommendations';
import Configuration from './insights_components/Configuration';

interface InsightsPageProps {
  setTopBarButton: (button: ReactNode) => void;
}

const InsightsPage: React.FC<InsightsPageProps> = ({ setTopBarButton }) => {
  const [activeTab, setActiveTab] = useState<string>('Overview');
  const [isConfigActive, setIsConfigActive] = useState<boolean>(false);

  useEffect(() => {
    if (activeTab === 'Recommendations') {
      setTopBarButton(
        <button
          className={`text-sm font-bold py-2 px-4 rounded-full items-center hidden lg:flex ${isConfigActive ? 'text-primarygray' : 'bg-success text-light'}`}
          onClick={() => setIsConfigActive(!isConfigActive)}
        >
          {isConfigActive ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
                alt="Configuration"
                className="w-[18px] h-[18px] mr-2 rotate-180"
              />
              BACK
            </>
          ) : (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/img/settings-01.svg`}
                alt="Configuration"
                className="w-[18px] h-[18px] mr-2"
              />
              CONFIGURATION
            </>
          )}
        </button>
      );
    } else {
      setTopBarButton(null);
    }
  }, [activeTab, isConfigActive, setTopBarButton]);

  const renderContent = () => {
    if (isConfigActive) {
      return <Configuration isOpen={isConfigActive} onClose={() => setIsConfigActive(false)} />;
    }
    switch (activeTab) {
      case 'Overview':
        return <Overview />;
      case 'Cluster':
        return <Cluster />;
      case 'Recommendations':
        return <Recommendations />;
      default:
        return null;
    }
  };

  const renderMenu = () => (
    <div className="sticky top-[94px] z-10 flex bg-body border-b border-gray300 whitespace-nowrap">
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${activeTab === 'Overview' ? 'border-b-2 border-primarygray' : ''}`}
        onClick={() => {
          setActiveTab('Overview');
          setIsConfigActive(false);
        }}
      >
        Overview
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${activeTab === 'Cluster' ? 'border-b-2 border-primarygray' : ''}`}
        onClick={() => {
          setActiveTab('Cluster');
          setIsConfigActive(false);
        }}
      >
        Cluster
      </div>
      <div
        className={`text-primarygray font-medium cursor-pointer py-4 px-8 ${activeTab === 'Recommendations' ? 'border-b-2 border-primarygray' : ''}`}
        onClick={() => {
          setActiveTab('Recommendations');
          setIsConfigActive(false);
        }}
      >
        Recommendations
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      {renderMenu()}
      <div className="flex-1">
        {renderContent()}
      </div>
    </div>
  );
};

export default InsightsPage;
