import React, { useEffect, useState } from 'react';

interface BaseSetupProps {
  formData: {
    agency: string;
    client: string;
    lineOfBusiness: string;
    market: string;
    region: string;
    new_business: boolean; // Added new_business flag
  };
  setFormData: React.Dispatch<React.SetStateAction<{
    agency: string;
    client: string;
    lineOfBusiness: string;
    market: string;
    region: string;
    new_business: boolean; // Added new_business flag
  }>>;
}

interface LineOfBusiness {
  id: number;
  name: string;
  available_markets: Market[];
}

interface Client {
  id: number;
  name: string;
  lines_of_business: LineOfBusiness[];
  available_markets: Market[];
}

interface Agency {
  id: number;
  name: string;
  clients: Client[];
}

interface Region {
  id: number;
  name: string;
}

interface Market {
  id: number;
  name: string;
  region: Region;
}


const BaseSetup: React.FC<BaseSetupProps> = ({ formData, setFormData }) => {
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState<LineOfBusiness[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchAvailableData = async () => {
      try {
        const response = await fetch('/get_available_data_base_set_up/');
        const data: { agencies: Agency[] } = await response.json();
        setAgencies(data.agencies);
      } catch (error) {
        console.error('Error fetching available data:', error);
      }
    };

    fetchAvailableData();
  }, []);

  useEffect(() => {
    if (formData.agency) {
      const selectedAgency = agencies.find(agency => agency.id === Number(formData.agency));
      if (selectedAgency) {
        setClients(selectedAgency.clients);
      }
      setErrors(prevErrors => ({ ...prevErrors, agency: '' }));
    } else {
      setClients([]);
      setErrors(prevErrors => ({ ...prevErrors, agency: 'Agency is required.' }));
    }
  }, [formData.agency, agencies]);

  useEffect(() => {
    if (formData.client) {
      const selectedClient = clients.find(client => client.id === Number(formData.client));
      if (selectedClient) {
        setLinesOfBusiness(selectedClient.lines_of_business || []);
        // If no LOB, use available markets at the client level
        setMarkets(selectedClient.available_markets || []);
      }
      setErrors(prevErrors => ({ ...prevErrors, client: '' }));
    } else {
      setLinesOfBusiness([]);
      setMarkets([]);
      if (formData.agency) {
        setErrors(prevErrors => ({ ...prevErrors, client: 'Client is required.' }));
      }
    }
  }, [formData.client, clients]);

  useEffect(() => {
    if (formData.lineOfBusiness) {
      const selectedLOB = linesOfBusiness.find(lob => lob.id === Number(formData.lineOfBusiness));
      if (selectedLOB) {
        setMarkets(selectedLOB.available_markets || []);
      }
      setErrors(prevErrors => ({ ...prevErrors, lineOfBusiness: '' }));
    } else {
      // If no LOB is selected, use client-level markets
      const selectedClient = clients.find(client => client.id === Number(formData.client));
      if (selectedClient) {
        setMarkets(selectedClient.available_markets || []);
      }
    }
  }, [formData.lineOfBusiness, linesOfBusiness, clients, formData.client]);

  useEffect(() => {
    if (formData.market) {
      const selectedMarket = markets.find(market => market.name === formData.market);
      if (selectedMarket) {
        setRegions([selectedMarket.region]);
        setFormData(prevState => ({ ...prevState, region: selectedMarket.region.name }));
      }
      setErrors(prevErrors => ({ ...prevErrors, market: '' }));
    } else {
      setRegions([]);
      setErrors(prevErrors => ({ ...prevErrors, market: 'Market is required.' }));
    }
  }, [formData.market, markets]);

  useEffect(() => {
    if (formData.region) {
      setErrors(prevErrors => ({ ...prevErrors, region: '' }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, region: 'Region is required.' }));
    }
  }, [formData.region]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
  };

  const handleToggleChange = () => {
    setFormData(prevState => ({ ...prevState, new_business: !prevState.new_business }));
  };

  return (
    <div>
      <h2 className="text-[32px] font-bold">Base Setup</h2>
      <h3 className="font-bold text-grayscale70 mt-2">Initial configuration</h3>
      <form className="mt-6 space-y-6">
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">Agency</label>
          <select
            id="agency"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.agency}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {agencies.map(agency => (
              <option key={agency.id} value={agency.id}>{agency.name}</option>
            ))}
          </select>
          {errors.agency && (
            <span className="text-error text-sm">{errors.agency}</span>
          )}
        </div>
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">Client</label>
          <select
            id="client"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.client}
            onChange={handleChange}
            disabled={!formData.agency}
          >
            <option value="">Select</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>{client.name}</option>
            ))}
          </select>
          {errors.client && (
            <span className="text-error text-sm">{errors.client}</span>
          )}
        </div>
        { linesOfBusiness.length > 0 && (
          <div>
            <label className="block font-bold text-primarygray text-2xl mb-2">Line of Business</label>
            <select
              id="lineOfBusiness"
              className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
              value={formData.lineOfBusiness}
              onChange={handleChange}
            >
              <option value="">Select</option>
              {linesOfBusiness.map(lob => (
                <option key={lob.id} value={lob.id}>{lob.name}</option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">Market</label>
          <select
            id="market"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.market}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {markets.map(market => (
              <option key={market.id} value={market.name}>{market.name}</option>
            ))}
          </select>
          {errors.market && (
            <span className="text-error text-sm">{errors.market}</span>
          )}
        </div>
        <div>
          <label className="block font-bold text-primarygray text-2xl mb-2">Region</label>
          <select
            id="region"
            className="w-full border border-gray200 rounded p-2 text-gray600 text-sm"
            value={formData.region}
            onChange={handleChange}
            disabled={true}
          >
            {regions.map(region => (
              <option key={region.id} value={region.name}>{region.name}</option>
            ))}
          </select>
          {errors.region && (
            <span className="text-error text-sm">{errors.region}</span>
          )}
        </div>
        <div className="flex items-center mt-4">
          <input
            type="checkbox"
            id="new_business"
            className="w-6 h-6"
            checked={formData.new_business}
            onChange={handleToggleChange}
          />
          <label className="ml-2 font-bold text-primarygray text-2xl">Is this New Business?</label>
        </div>
      </form>
    </div>
  );
};

export default BaseSetup;