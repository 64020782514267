import { APP_ROUTES } from '../constants';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface TopBarProps {
  isSidebarOpen: boolean;
  button: React.ReactNode;
}

const tooltipTexts: { [key: string]: string } = {
  '/auto_optimize': 'Data updates after 3 days.',
  '/insights': 'Daily data updates.',
  '/resources':
    'This information is updated regularly by our global product team.',
};

function TopBar({ isSidebarOpen, button }: TopBarProps) {
  const { pathname } = useLocation();

  let pageTitle = '';
  let titleDesc: React.ReactNode;
  let showTooltip = false;
  let tooltipText = '';

  switch (pathname) {
    case APP_ROUTES.HOME:
      pageTitle = 'Home';
      titleDesc =
        'Holistic search strategy, analysis and optimization opportunities.';
      break;

    case APP_ROUTES.CONSUMER_INTENT:
    case APP_ROUTES.CON_INTENT_OVERVIEW:
    case APP_ROUTES.CON_INTENT_PFA:
    case APP_ROUTES.CON_INTENT_RETAILER_COMP:
    case APP_ROUTES.CON_INTENT_BRAND_COMP:
    case APP_ROUTES.CON_INTENT_QUERY_VIEW:
      pageTitle = 'Consumer Intent';
      titleDesc =
        'Search Intelligence to fuel consumer and trend driven strategies.';
      break;

    case APP_ROUTES.COMPETITIVE_INTEL:
    case APP_ROUTES.COMP_INTEL_OVERVIEW:
    case APP_ROUTES.COMP_INTEL_BRAND_FOCUS:
    case APP_ROUTES.COMP_INTEL_CONTENT_ANALYSIS:
    case APP_ROUTES.COMP_INTEL_REPORTS:
      pageTitle = 'Competitive Intelligence';
      titleDesc =
        'Competitor and market insights to understand the competitive landscape.';
      break;
    case APP_ROUTES.AUTO_OPTIMIZE:
      pageTitle = 'Auto Optimize';
      titleDesc = 'Increase holistic brand efficiency.';
      showTooltip = true;
      tooltipText = tooltipTexts[location.pathname];
      break;
    case APP_ROUTES.INSIGHTS:
      pageTitle = 'Insights';
      titleDesc = 'Holistic search data and recommendations.';
      showTooltip = true;
      tooltipText = tooltipTexts[location.pathname];
      break;
    case APP_ROUTES.SETTINGS:
      pageTitle = 'Settings';
      titleDesc = 'Overview of your settings.';
      break;
    case APP_ROUTES.ON_BOARD:
      pageTitle = 'Onboarding';
      titleDesc = 'Setup and integration of search tools.';
      break;
    case APP_ROUTES.RESOURCES:
      pageTitle = 'Resources';
      titleDesc =
        'The most important data definitions and frequently asked questions.';
      showTooltip = true;
      tooltipText = tooltipTexts[location.pathname];
      break;
    case APP_ROUTES.NO_ACCESS:
      pageTitle =
        'You do not have access - Contact support to set up your user.';
      titleDesc = (
        <>
          <p>Please reach out to your regional enablement team:</p>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            <li>
              US:{' '}
              <a
                href="mailto:onesearch_enablement_us@publicismedia.com"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_enablement_us@publicismedia.com
              </a>
            </li>
            <li>
              EMEA:{' '}
              <a
                href="mailto:onesearch_enablement_EMEA@publicisgroupe.net"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_enablement_EMEA@publicisgroupe.net
              </a>
            </li>
            <li>
              All others:{' '}
              <a
                href="mailto:onesearch_global@performics.com"
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                onesearch_global@performics.com
              </a>
            </li>
          </ul>
        </>
      );
      break;
    default:
      pageTitle = 'Page Not Found';
      titleDesc = 'The page you are looking for does not exist.';
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div
        className={`fixed top-0 ${
          location.pathname === '/please-wait' || location.pathname === '/final'
            ? 'right-0 w-full'
            : location.pathname === '/onboard'
            ? 'right-[280px] w-[calc(100vw-280px)]'
            : isSidebarOpen
            ? 'left-[280px] w-[calc(100vw-280px)]'
            : 'left-[60px] w-[calc(100vw-60px)]'
        } bg-light shadow-md p-6 z-50 flex justify-between items-center transition-all duration-300`}
      >
        <div>
          <div className="text-primarygray font-semibold text-base">
            {pageTitle}
          </div>
          <div className="flex items-center text-gray400 font-medium text-sm">
            <span>{titleDesc}</span>
            {showTooltip && (
              <div className="relative ml-1 mb-2 group inline-block">
                <img
                  src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
                  alt="tooltip"
                  className="ml-2"
                />
                <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 ml-1 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
                  <p className="px-4 py-2">{tooltipText}</p>
                  <img
                    src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`}
                    alt="arrow"
                    className="absolute left-1/2 transform -translate-x-1/2 -top-[6px] rotate-180"
                  />
                </span>
              </div>
            )}
          </div>
        </div>
        {button && <div>{button}</div>}
      </div>
    </>
  );
}

export default TopBar;
