import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, ChartData, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
Chart.register(CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Info {
    transactions: string;
    revenue: string;
}


const fetchInsightsData = async () => {
    const response = await fetch('/api/home/');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
  };
  
const Insights: React.FC = () => {
    const [insight_blur, setBlur] = useState(false);

    const { data, error, isLoading } = useQuery('insightsData', fetchInsightsData, {
        onSuccess: (data) => {
            setBlur(data.insights_data.insightsBlur);
        }
    });

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/insights');
    };

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <div>Error fetching insights data: {(error as Error).message}</div>;
    }

    if (!data || !data.insights_data || data.insights_data.data.length === 0) {
        return <div></div>
    }

    const labels = data.insights_data.data.map((item: { label: string; value: number }) => item.label);
    const values = data.insights_data.data.map((item: { label: string; value: number }) => item.value);

    const insights_data: ChartData<'line'> = {
        labels: labels,
        datasets: [{
            label: 'Transactions',
            data: values,
            borderColor: '#222222CC',
            backgroundColor: 'rgba(34, 34, 34, 0.80)',
            fill: false,
            pointRadius: 2,
            pointBackgroundColor: '#FFFFFF',
            pointBorderWidth: 1,
            borderWidth: 1,
            tension: 0.0
        }]
    };

    const info: Info = {
        transactions: data.insights_data.details.transactions.title,
        revenue: data.insights_data.details.revenue.title
    };

    const formatNumber = (num: number): string => {
        const thresholds = [
          { value: 1e9, suffix: 'B' },
          { value: 1e6, suffix: 'M' },
          { value: 1e3, suffix: 'K' }
        ];
      
        for (const threshold of thresholds) {
          if (num >= threshold.value) {
            return (num / threshold.value).toFixed(1) + threshold.suffix;
          }
        }
      
        return num.toString();
      };

    return (
        <div className="bg-light rounded-lg shadow-md h-[413px] w-full lg:h-auto">
            {insight_blur && <span className="block bg-light text-primarygray p-4 mb-4 rounded">NO DATA FOR THIS PRODUCT</span>}
            <div className={`relative ${insight_blur ? 'filter blur-sm' : ''}`}>
                <div className="px-4 py-2 flex justify-between items-center border-b border-success">
                    <div>
                        <h3 className="text-primarygray text-2xl font-bold cursor-pointer tracking-[0.48px]" onClick={handleRedirect}>Insights
                            <div className="relative ml-1 mb-2 group inline-block">
                                <img src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`} alt="tooltip" className="tooltip-image" />
                                <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-[291px] h-12 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-50 invisible group-hover:visible transition-opacity duration-300">
                                    Leveraging client data from search advertising platforms, SEO analytics data 
                                <img src={`${process.env.PUBLIC_URL}/static/img/bottom_arrow.svg`} alt="arrow" className="absolute left-1/2 transform -translate-x-1/2 -bottom-[6px]" />
                                </span>
                            </div>
                        </h3>
                        <h6 className="text-sm text-gray400 font-semibold flex items-center">Holistic search data and recommendations</h6>
                    </div>
                    <h6 className="text-sm font-semibold text-gray400 mt-8">Past 30 days</h6>
                </div>
                <div className="flex">
                    <div className="py-6 px-4 flex-1 min-w-[200px] flex flex-col justify-center">
                        <div className="mb-4">
                            <h6 className="text-sm font-medium text-thirdgray">Holistic Transactions</h6>
                            <div className="flex items-center mt-2">
                                <h3 className="text-primarygray text-3xl font-medium">{info.transactions}</h3>
                            </div>
                        </div>
                        <div>
                            <h6 className="text-sm font-medium text-thirdgray">Holistic Revenue</h6>
                            <div className="flex items-center mt-2">
                                <h3 className="text-primarygray text-3xl font-medium">{info.revenue}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 py-6 px-4">
                        {isLoading ? (
                            <p className="text-thirdgray">Loading data...</p>
                        ) : (
                            <div className='w-[307px] h-[251px] 2xl:w-[370px] 3xl:w-[500px]'>
                                <Line data={insights_data} options={{
                                    scales: {
                                        x: { 
                                            type: 'time',
                                            time: {
                                                unit: 'day',
                                                tooltipFormat: 'dd/MM',
                                                displayFormats: {
                                                    day: 'dd/MM'
                                                }
                                            },
                                            ticks: { 
                                                display: true,
                                                color: '#333333',
                                                maxRotation: 0,
                                                minRotation: 0,
                                                autoSkip: true,
                                                maxTicksLimit: Math.ceil((insights_data.labels as string[]).length / 10),
                                                // callback: function(value, index) {
                                                //     const label = (insights_data.labels as string[])[index];
                                                //     return format(new Date(label), 'dd/MM');
                                                // },
                                                font: {
                                                    size: 12, // Set font size
                                                    style: 'normal', // Set font style
                                                    weight: 400, // Set font weight
                                                    lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                                                }
                                            },
                                            border: { 
                                                color: '#333333',
                                                display: true
                                            },
                                            grid: { 
                                                color: '#B3B3B3' // Display vertical grid lines
                                            }
                                        },
                                        y: {
                                            title: { 
                                                display: true, 
                                                text: 'Transactions',
                                                font: {
                                                    size: 14, // Set font size
                                                    style: 'normal', // Set font style
                                                    weight: 700, // Set font weight
                                                    lineHeight: 1.28, // Set line height (18px / 14px = 1.28)
                                                },
                                                align: 'center'
                                            },
                                            ticks: { 
                                                display: true,
                                                color: '#000',
                                                callback: function (value) {
                                                    const numericValue = Number(value);
                                                    return formatNumber(numericValue);
                                                  },
                                                font: {
                                                    size: 12, // Set font size
                                                    style: 'normal', // Set font style
                                                    weight: 400, // Set font weight
                                                    lineHeight: 1.33, // Set line height (16px / 12px = 1.33)
                                                },
                                                align: 'center'
                                            },
                                            border: { 
                                                color: '#333333',
                                                display: true
                                            },
                                            grid: { 
                                                color: '#B3B3B3' // Display vertical grid lines
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: { display: false }
                                    },
                                    interaction: {
                                        mode: 'index',
                                        intersect: false
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true
                                }} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex px-4 py-2 justify-end">
                    <a className="inline-flex items-center w-[110px] h-[30px] justify-center text-thirdgray text-sm font-normal bg-fourthgray rounded-[8px] cursor-pointer" onClick={handleRedirect}>
                        See more
                        <img src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`} className="w-4 h-4 bg-contain bg-center ml-1" alt="right-arrow" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Insights;
