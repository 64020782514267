import React, { useState, useEffect, useRef } from 'react';

interface MultiDropdownProps {
  placeholder?: string;
  options: string[];
  preselectedOptions: string[];
  allOption?: string;
  maxNChoices?: number;
  onOptionClick?: (option: string) => void;
  onOptionChange?: (option: string[]) => void;
  dropdownLength?: string;
  dropdownWidth?: string;
  mode?: string;
  className?: string;
}

const MultiDropdown: React.FC<MultiDropdownProps> = ({ placeholder, options, preselectedOptions=[], allOption='', maxNChoices=Infinity, onOptionClick=() => {}, onOptionChange=() => {}, dropdownLength='max-h-56', dropdownWidth='max-w-40', mode='light', className='' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(preselectedOptions);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedItems.length === options.length && selectedItems.includes(allOption)) {
      setSelectedItems(options);
    }
  }, [selectedItems, options, allOption]);

  const toggleMultiDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    let updatedSelectedItems: string[] = [];

    if (option === allOption) {
      if (selectedItems.length === options.length) {
        // Deselect all
        updatedSelectedItems = [];
      } else {
        // Select all
        updatedSelectedItems = options;
      }
    } else {
      if (selectedItems.includes(option)) {
        updatedSelectedItems = selectedItems.filter((item) => item !== option);
      } else {
        if (selectedItems.length >= maxNChoices) return;
        updatedSelectedItems = [...selectedItems, option];
      }
    }

    setSelectedItems(updatedSelectedItems);
    onOptionChange(updatedSelectedItems);
    onOptionClick(option);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedOptions = [allOption, ...filteredOptions];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      onClick={toggleMultiDropdown}
      className={`${className} text-sm relative
      ${mode === 'dark' ? 'bg-primarygray text-white' : 'bg-white hover:bg-gray-100 border text-primarygray'}
      py-1.5 pl-4 pr-4 rounded font-medium cursor-pointer whitespace-nowrap`}
    >
      <div className='flex justify-between items-center gap-4 text-center select-none'>
        <span className={`${dropdownWidth} truncate`}>
          {selectedItems.length > 0 ? `${selectedItems.join(', ')}` : placeholder}
        </span>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/right-arrow.svg`}
          className={`h-3 w-3 my-1 ml-1 transform ${isOpen ? '-rotate-90' : 'rotate-90'}`}
          alt="Arrow"
        />
      </div>
      {isOpen && (
        <div
          className={`absolute z-10 ${
            mode === 'dark' ? 'bg-primarygray text-white border-white divide-white' : 'bg-white border-gray300 divide-gray300'
          } shadow-md border divide-y rounded-lg right-0 top-10 text-center ${dropdownLength} min-w-48 max-w-72 overflow-y-auto`}
        >
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            type="text"
            className="w-full px-4 py-2 border-b border-gray-300 focus:outline-none"
            placeholder={
              maxNChoices === Infinity
                ? 'Search...'
                : `Search...(max choices ${maxNChoices})`
            }
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {sortedOptions.map((option, index) => (
            <div
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(option);
              }}
              className={`select-none flex items-center gap-2 py-1.5 px-6 ${
                mode === 'dark' ? 'hover:bg-gray-800' : 'hover:bg-gray-200'
              }`}
            >
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={selectedItems.includes(option) || (option === allOption && selectedItems.length === options.length)}
                onChange={() => {}}
                value={option}
              />
              <div className="cursor-pointer">{option}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiDropdown;