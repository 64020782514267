import moment from 'moment';
import { useQuery } from 'react-query';
import { addDays, subDays } from 'date-fns';
import { downloadImage, downloadXLS } from 'utils/download';
import React, { useState, useRef, useEffect, useMemo } from 'react';

// COMPONENTS
import ChangeLog from './AO_comp/ChangeLog';
import MultiDropdown from './MultiDropdown';
import LoadingSpinner from './LoadingSpinner';
import Card, { CardProps } from './AO_comp/Card';
import FilterBarWrapper from './FilterBarWrapper';
import OrganicPaidImp from './AO_comp/OrganicPaidImp';
import DatepickerComponent from './DatepickerComponent';
import CostVsExpectCost from './AO_comp/CostVsExpectCost';
import OrganicPaidClick from './AO_comp/OrganicPaidClick';

// TYPES
import { DateValueType } from 'react-tailwindcss-datepicker';

export interface ChangeLogItem {
  keyword: string;
  on_count: number;
  off_count: number;
}

export interface ChangeLogData {
  change_log: Record<string, ChangeLogItem[]>;
  change_log_daily: Record<string, { on_count: number; off_count: number }>;
  change_log_summary: {
    on: number;
    off: number;
  };
}

const initialChangeLogData: ChangeLogData = {
  change_log: {},
  change_log_daily: {},
  change_log_summary: {
    on: 0,
    off: 0,
  },
};

function getCSRFToken() {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return cookieValue;
}

const fetchOsabData = async (
  dateRange: DateValueType,
  keywords: string = '',
  market: string = 'US'
) => {
  if (!dateRange?.endDate || !dateRange.startDate) {
    throw new Error('Start and end dates required.');
  }

  const csrfToken = getCSRFToken();
  const response = await fetch('/get_osab_data/', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...(csrfToken && { 'X-CSRFToken': csrfToken }),
    }),
    body: JSON.stringify({
      date_from: moment(dateRange.startDate).format('YYYY-MM-DD'),
      date_to: moment(dateRange.endDate).format('YYYY-MM-DD'),
      keyword: keywords,
      market,
    }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

interface AutoOptimizeProps {
  currentClient: string;
}

function AutoOptimize({ currentClient }: AutoOptimizeProps) {
  const [market] = useState('US');
  const [cardData, setCardData] = useState<CardProps[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState<string>('');

  const [costVsExpectedCostData, setCostVsExpectedCostData] = useState<
    Record<string, { cost: number; expected_cost: number }>
  >({});
  const [organicVsPaidClicksData, setOrganicVsPaidClicksData] = useState<
    Record<string, { organic_clicks: number; paid_clicks: number }>
  >({});
  const [organicVsPaidImpressionsData, setOrganicVsPaidImpressionsData] =
    useState<
      Record<string, { organic_impressions: number; paid_impressions: number }>
    >({});
  const [changeLogData, setChangeLogData] =
    useState<ChangeLogData>(initialChangeLogData);

  const [dateRange, setValue] = useState<DateValueType>({
    startDate: addDays(new Date(), -90), // 90 days before today
    endDate: subDays(new Date(), 4), // Today minus 4 days
  });

  const keywordOptions = useMemo(() => {
    if (!changeLogData?.change_log) return [];

    const dates = Object.keys(changeLogData.change_log).sort();
    const recentDate = dates[dates.length - 1];

    return (
      changeLogData.change_log?.[recentDate]?.map(({ keyword }) => keyword) ||
      []
    );
  }, [changeLogData.change_log]);

  const { data, error, isLoading } = useQuery(
    [
      'osabData',
      dateRange?.startDate,
      dateRange?.endDate,
      market,
      currentClient,
      selectedKeywords,
    ],
    () => fetchOsabData(dateRange, selectedKeywords, market),
    {
      enabled: !!dateRange?.startDate && !!dateRange?.endDate,
    }
  );

  useEffect(() => {
    if (data) {
      const formatNumber = (value: number) => value.toLocaleString();

      const getPercentageChange = (value: number | null) =>
        value !== null && typeof value === 'number'
          ? `${value.toFixed(1)}%`
          : 'N/A';
      const getIsPositiveOrNA = (value: number | null) => {
        if (typeof value === 'string' && value === 'Invalid') {
          return 'Invalid';
        }
        return value === null || value > 0;
      };

      const summaryData = data.summary;
      const diffData = data.diff_summary;
      const cardProps: CardProps[] = [
        {
          title: 'Estimated Savings',
          value: `$${formatNumber(parseInt(summaryData.estimated_savings))}`,
          change: `${getPercentageChange(diffData.estimated_savings)}`,
          isPositive: getIsPositiveOrNA(diffData.estimated_savings),
          styleType: 'light',
          tooltipText: 'Estimated savings generated',
          tooltipPosition: 'above',
        },
        {
          title: 'Holistic CPC',
          value: `$${formatNumber(summaryData.hollistic_cpc.toFixed(2))}`,
          change: `${getPercentageChange(diffData.hollistic_cpc)}`,
          isPositive: getIsPositiveOrNA(diffData.hollistic_cpc),
          styleType: 'light',
          tooltipText: [
            'Paid Search cost divided by',
            'Paid Search and Organic Search clicks',
          ],
          invert: true,
        },
        {
          title: 'Total Clicks',
          value: `${formatNumber(summaryData.total_clicks)}`,
          change: `${getPercentageChange(diffData.total_clicks)}`,
          isPositive: getIsPositiveOrNA(diffData.total_clicks),
          styleType: 'light',
          tooltipText: 'Organic and Paid Search clicks',
          tooltipPosition: 'above',
        },
        {
          title: 'Organic CTR(%)',
          value: `${formatNumber(summaryData.organic_ctr)}%`,
          change: `${getPercentageChange(diffData.organic_ctr)}`,
          isPositive: getIsPositiveOrNA(diffData.organic_ctr),
          styleType: 'light',
        },
        {
          title: 'Organic Impressions',
          value: `${formatNumber(summaryData.organic_impressions)}`,
          change: `${getPercentageChange(diffData.organic_impressions)}`,
          isPositive: getIsPositiveOrNA(diffData.organic_impressions),
          styleType: 'light',
        },
        {
          title: 'Organic Clicks',
          value: `${formatNumber(summaryData.organic_clicks)}`,
          change: `${getPercentageChange(diffData.organic_clicks)}`,
          isPositive: getIsPositiveOrNA(diffData.organic_clicks),
          styleType: 'light',
        },
      ];

      setCardData(cardProps);
      setCostVsExpectedCostData(data.cost_vs_expected_cost);
      setOrganicVsPaidClicksData(data.organic_vs_paid_clicks);
      setOrganicVsPaidImpressionsData(data.organic_vs_paid_impressions);
      setChangeLogData({
        change_log: data.change_log,
        change_log_daily: data.change_log_daily,
        change_log_summary: data.change_log_summary,
      });
    }
  }, [data]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleValueChange = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setValue({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // HANDLE KEYWORD SELECTIONS
  const onSelectKeywords = (selections: string[]) => {
    setSelectedKeywords(
      selections.length === keywordOptions.length ? '' : selections.join(',')
    );
  };

  const preselectedOptions = useMemo(() => {
    if (keywordOptions && !selectedKeywords) {
      return [...keywordOptions];
    }

    return selectedKeywords.split(',');
  }, [keywordOptions, selectedKeywords]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="pt-8">
      <FilterBarWrapper topPos="top-[94px]">
        <div className="flex-1" />
        <MultiDropdown
          placeholder={'Query'}
          options={keywordOptions}
          preselectedOptions={preselectedOptions}
          onSelectionChange={(selections) => onSelectKeywords(selections)}
          dropdownLength="max-h-60"
        />

        <div className="flex items-center">
          <DatepickerComponent
            value={dateRange}
            onChange={handleValueChange}
            product="osab"
          />
          <div className="relative mb-4 mx-2 mr-4 group inline-block">
            <img
              src={`${process.env.PUBLIC_URL}/static/img/tooltip.svg`}
              alt="tooltip"
              className="tooltip-image"
            />
            <span className="absolute bottom-full right-20 transform translate-x-1/2 mb-2 bg-gray100 text-thirdgray font-normal text-center text-xs rounded-lg flex items-center justify-center z-10 invisible group-hover:visible transition-opacity duration-300 whitespace-nowrap">
              <p className="px-4 py-2">
                The Auto Optimize date selector
                <br />
                defaults to a four-day delay
                <br />
                to provide accurate data.
              </p>
              <div className="custom-arrowdown border-t-[10px] border-gray100 absolute -bottom-1.5 right-6"></div>
            </span>
          </div>
        </div>
      </FilterBarWrapper>
      <div className="bg-transparent border-b border-success mb-4 pb-2 flex justify-between items-center mt-8">
        <div>
          <h2 className="text-primarygray text-2xl font-bold tracking-wide">
            Performance
          </h2>
          <p className="text-gray800 text-sm font-bold leading-[18px]">
            Time frame chose vs. same time period from the prior year
          </p>
        </div>
        <div
          className="relative"
          ref={dropdownRef}
          id="downloadDropdownMenu-autoOptimize"
        >
          <img
            src={`${process.env.PUBLIC_URL}/static/img/dots-vertical.svg`}
            alt="dots"
            className="h-6 w-6 mr-4 cursor-pointer"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-light text-sm text-gray700 rounded-md shadow-lg z-50">
              <ul>
                {/* <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={closeDropdown}>View data table</li> */}
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    closeDropdown();
                    downloadXLS(
                      'autoOptimize',
                      ['card name', 'value'],
                      cardData.map((item) => item.title),
                      [cardData.map((item) => item.value)]
                    );
                  }}
                >
                  Download XLS
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    closeDropdown();
                    downloadImage('autoOptimize');
                  }}
                >
                  Download PNG image
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-6 gap-6 mb-8 whitespace-nowrap"
        id="autoOptimize"
      >
        {cardData.map((props, index) => (
          <Card key={index} {...props} />
        ))}
      </div>
      <CostVsExpectCost data={costVsExpectedCostData} />
      <OrganicPaidClick data={organicVsPaidClicksData} />
      <OrganicPaidImp data={organicVsPaidImpressionsData} />
      <ChangeLog {...changeLogData} />
    </div>
  );
}

export default AutoOptimize;
