import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';

interface Competitor {
  competitor_id: number;
  domain: string;
  active: boolean;
  customer_id: string;
}

interface Domain {
  domain_id: number;
  domain: string;
  active: boolean;
  customer_id: string;
}

interface Filter {
  filter_id: number;
  level: string;
  string: string;
  customer_id?: string;
}

interface Query {
  account: string;
  query_id: number;
  campaign: string;
  ad_group: string;
  query: string;
  country: string;
  language: string;
  campaign_negative_matching: string;
  ignore: boolean;
  default: string;
  current_status: string;
  customer_id: string;
}

interface Configuration {
  config_id: number;
  date_from: string;
  date_to: string;
  filtering: {
    filter_id: number;
    level: string;
    string: string;
  }[];
  our_domains_list: Domain[];
  competitor_list: Competitor[];
  query_list: Query[];
  filter_list: Filter[];
}

interface CustomerInfo {
  customer_id: string;
  agency: string;
  client: string;
  line_of_business: string;
  market: string;
  region: string;
  platform: string[];
}

interface AutoOptimizeData {
  customer_info: CustomerInfo;
  configurations: Configuration[];
}

interface TableProps<T> {
  data: T[];
  columns: (keyof T)[];
  onEdit?: (item: T) => void;
  onDelete?: (id: number) => void;
  toggleView?: (id: number) => void;
  toggleOnOff?: (id: number, column: keyof T) => void;
  onAdd?: () => void;
  hideEditButton?: boolean;
}

function formatHeader(header: string): string {
  let formatted = header.replace(/_/g, ' ');
  formatted = formatted.replace(/([A-Z])/g, ' $1');
  return formatted.toUpperCase();
}

function Table<T extends { competitor_id?: number; query_id?: number; domain_id?: number; filter_id?: number; }>({
  data,
  columns,
  onEdit,
  onDelete,
  toggleView,
  toggleOnOff,
  onAdd,
  hideEditButton = false
}: TableProps<T>) {
  
  const isFilteringData = data.some((item) => 'filter_id' in item);

  const handleLevelChange = (itemId: number, newLevel: string) => {
    const filter = data.find((filter) => filter.filter_id === itemId) as Filter | undefined;
    if (filter && onEdit) {
      onEdit({ ...filter, level: newLevel } as unknown as T);
    }
  };

  const handleStringChange = (itemId: number, newString: string) => {
    const filter = data.find((filter) => filter.filter_id === itemId) as Filter | undefined;
    if (filter && onEdit) {
      onEdit({ ...filter, string: newString } as unknown as T);
    }
  };

  return (
    <div className="table-container overflow-auto max-h-96 relative">
      <table className="min-w-full bg-light table-auto">
        <thead className="text-primarygray text-xs font-normal">
          <tr>
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 text-left">
                <div className="flex items-center">
                  {formatHeader(column as string)}
                  {column !== 'current_status' && !isFilteringData && (
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/switch-vertical.svg`}
                      alt="sort-icon"
                      className="ml-2"
                    />
                  )}
                </div>
              </th>
            ))}
            <th className="py-2 px-2 text-right">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="text-primarygray text-xs font-normal rounded-md">
          {data.map((item, index) => (
            <tr
              key={item.filter_id || item.competitor_id || item.query_id || item.domain_id}
              className={index % 2 === 0 ? 'bg-gray50' : 'bg-gray100'}
            >
              {columns.map((column) => (
                <td key={column as string} className="py-2 px-4">
                  {column === 'level' ? (
                    <select
                      className="rounded-md px-2 py-1 bg-white border border-gray200"
                      value={String(item[column] || '')}
                      onChange={(e) => handleLevelChange(item.filter_id as number, e.target.value)}
                    >
                      <option value="campaign.name">Campaign Name</option>
                      <option value="adGroup.name">AdGroup Name</option>
                      <option value="customer.descriptive_name">Customer Descriptive Name</option>
                    </select>
                  ) : column === 'string' ? (
                    <input
                      type="text"
                      className="rounded-md px-2 py-1 border border-gray200 w-full"
                      value={item[column] as string}
                      onChange={(e) => handleStringChange(item.filter_id as number, e.target.value)}
                    />
                  ) : column === 'current_status' ? (
                    <div
                      className={`rounded-md px-4 py-1 inline-block text-light ${
                        item[column] === 'ON' ? 'bg-success' : 'bg-error'
                      }`}
                    >
                      {String(item[column])}
                    </div>
                  ) : typeof item[column] === 'boolean' ? (
                    <label className="inline-flex items-center cursor-pointer mr-2">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={item[column] as boolean}
                        onChange={() => {
                          if (toggleView) {
                            toggleView(item.query_id ?? (item.competitor_id as number));
                            toggleView(item.domain_id as number);
                        }
                        
                        }}
                      />
                      <div className="relative w-10 h-5 bg-gray200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-light after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-light after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-success"></div>
                    </label>
                  ) : item[column] === 'ON' || item[column] === 'OFF' ? (
                    <select
                      className={`rounded-full px-4 py-1 inline-block text-light ${
                        item[column] === 'ON' ? 'bg-success' : 'bg-error'
                      }`}
                      onChange={(e) => toggleOnOff && toggleOnOff(item.query_id as number, column)}
                      value={String(item[column])}
                    >
                      <option value="ON">ON</option>
                      <option value="OFF">OFF</option>
                    </select>
                  ) : (
                    String(item[column])
                  )}
                </td>
              ))}
                <td className="py-2 px-2 flex justify-end">
                {!hideEditButton && (
                  <>
                    <button onClick={() => onEdit && onEdit(item)}>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/img/edit-03.svg`}
                        alt="edit"
                        className="h-4 w-4 mr-2"
                      />
                    </button>
                  </>
                )}
                  <button
                    onClick={() => {
                      const id = item.filter_id || item.competitor_id || item.query_id || item.domain_id;
                      if (id !== undefined) {
                        onDelete && onDelete(id);
                      }
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/static/img/delete_option.svg`}
                      alt="delete"
                      className="h-4 w-4"
                    />
                  </button>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border border-thirdgray rounded-[20px] px-1 mt-2 w-[68px]">
        <button className="text-sm text-thirdgray font-bold flex items-center" onClick={onAdd}>
          <img
            src={`${process.env.PUBLIC_URL}/static/img/plus_gray.svg`}
            alt="plus_icon"
            className="w-[18px] h-[18px] mr-1"
          />
          ADD
        </button>
      </div>
    </div>
  );
}

interface AutoOptimizeProps {
  customerId: string;
  enabled: boolean;
}

const fetchAutoOptimizeData = async (customerId: string) => {
  const response = await fetch('/auto-optimize-list/');
  const data: AutoOptimizeData[] = await response.json();
  const filteredData = data.find((d) => d.customer_info.customer_id === customerId);
  return filteredData || null;
};

const createCompetitor = async (competitor: Competitor) => {
  try {
    const response = await fetch('/create-competitor/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(competitor),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create competitor failed: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createDomain = async (domain: Domain) => {
  try {
    const response = await fetch('/create-domain/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(domain),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create domain failed: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createQuery = async (query: Query) => {
  try {
    const response = await fetch('/create-query/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create query failed: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const createFilter = async (filter: Filter) => {
  try {
    const response = await fetch('/create-filter/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filter),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Create filter failed: ${errorText}`);
    }

    return response.json();
  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const updateConfiguration = async (config: Configuration) => {

  try {
    const response = await fetch(`/edit-configuration-osab/${config.config_id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(config),
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`Update dates failed: ${errorText}`);
      return null;
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

const updateCompetitor = async (competitor: Competitor) => {
  const response = await fetch(`/update-competitor/${competitor.competitor_id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(competitor),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const updateQuery = async (query: Query) => {
  const response = await fetch(`/update-query/${query.query_id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteCompetitor = async (competitorId: number) => {
  const response = await fetch(`/delete-competitor/${competitorId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteQuery = async (queryId: number) => {
  const response = await fetch(`/delete-query/${queryId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const updateDomain = async (domain: Domain) => {
  const response = await fetch(`/update-domain/${domain.domain_id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(domain),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteDomain = async (domainId: number) => {
  const response = await fetch(`/delete-domain/${domainId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const updateFilter = async (filter: Filter) => {
  const response = await fetch(`/update-filter/${filter.filter_id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filter),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const deleteFilter = async (filterId: number) => {
  const response = await fetch(`/delete-filter/${filterId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

const uploadFile = async ({ autoOptimizeFileCFG, customerId, dateFrom, dateTo }: { autoOptimizeFileCFG: File, customerId: string, dateTo: string, dateFrom: string }) => {

  const formData = new FormData();
  formData.append('file', autoOptimizeFileCFG);
  formData.append('product', 'osab');

  if (customerId) {
    formData.append('customer_id', customerId);
  }
  formData.append('date_from', dateFrom);
  formData.append('date_to', dateTo);

  try {
    const response = await fetch('/upload/', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
      alert(`File upload failed: ${errorText}`);
      return null;
    }

    return response.json();

  } catch (error) {
    const err = error as Error;
    alert(`An error occurred: ${err.message}`);
    return null;
  }
};

function AutoOptimize({ customerId, enabled }: AutoOptimizeProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(['AutoOptimizeData', customerId], () =>
    fetchAutoOptimizeData(customerId)
  );

  const [isAdding, setIsAdding] = useState(false);
  const [isAddingDomain, setIsAddingDomain] = useState(false);
  const [newQuery, setNewQuery] = useState<Query | null>(null);
  const [newFilter, setNewFilter] = useState<Filter | null>(null);
  const [isQuerying, setIsQuerying] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [newCompetitor, setNewCompetitor] = useState<Competitor | null>(null);
  const [newDomain, setNewDomain] = useState<Domain | null>(null);

  const [autoOptimizeFileCFG, setAutoOptimizeFileCFG] = useState<File | null>(null);
  const [dateFrom, setdateFrom] = useState<string>('');
  const [dateTo, setdateTo] = useState<string>('');

  const [initialDateFrom, setInitialDateFrom] = useState<string>('');
  const [initialDateTo, setInitialDateTo] = useState<string>('');
  
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  
  const [editedFilters, setEditedFilters] = useState<Filter[]>([]);
  const [initialFilters, setInitialFilters] = useState<Filter[]>([]);
  const [hasFilterChanges, setHasFilterChanges] = useState<boolean>(false);

  const [editableCompetitor, setEditableCompetitor] = useState<Competitor | null>(null);
  const [editableDomain, setEditableDomain] = useState<Domain | null>(null);
  const [editableQuery, setEditableQuery] = useState<Query | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editType, setEditType] = useState<'competitor' | 'domain' | 'query' | null>(null);


  useEffect(() => {
    if (data && data.configurations.length > 0) {
      const config = data.configurations[0];
      setdateFrom(config.date_from);
      setdateTo(config.date_to);

      setInitialDateFrom(config.date_from);
      setInitialDateTo(config.date_to);

      const filters = data.configurations.flatMap((config) => config.filtering);
      setEditedFilters(filters);
      setInitialFilters(filters);
    }
  }, [data]);

  const addCompetitorMutation = useMutation(createCompetitor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setIsAdding(false);
    },
  });

  const addDomainMutation = useMutation(createDomain, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setIsAddingDomain(false);
    },
  });

  const addQueryMutation = useMutation(createQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setIsQuerying(false);
    }
  })

  const addFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setIsFiltering(false);
    }
  })

  const updateCompetitorMutation = useMutation(updateCompetitor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const updateQueryMutation = useMutation(updateQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const deleteCompetitorMutation = useMutation(deleteCompetitor, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const deleteQueryMutation = useMutation(deleteQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const updateDomainMutation = useMutation(updateDomain, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });
  
  const deleteDomainMutation = useMutation(deleteDomain, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });
  
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const deleteFilterMutation = useMutation(deleteFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
    },
  });

  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setAutoOptimizeFileCFG(null);
    },
  });

  const updateConfigurationMutation = useMutation(updateConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['AutoOptimizeData', customerId]);
      setHasChanges(false);
    },
  });

  useEffect(() => {
    if (
      updateCompetitorMutation.isSuccess ||
      updateQueryMutation.isSuccess ||
      deleteCompetitorMutation.isSuccess ||
      deleteQueryMutation.isSuccess ||
      updateDomainMutation.isSuccess ||
      deleteDomainMutation.isSuccess ||
      updateFilterMutation.isSuccess ||
      deleteFilterMutation.isSuccess ||
      uploadMutation.isSuccess ||
      updateConfigurationMutation.isSuccess ||
      addCompetitorMutation.isSuccess ||
      addDomainMutation.isSuccess ||
      addQueryMutation.isSuccess ||
      addFilterMutation.isSuccess
    ) {
      refetch();
    }
  }, [
    updateCompetitorMutation.isSuccess,
    updateQueryMutation.isSuccess,
    deleteCompetitorMutation.isSuccess,
    deleteQueryMutation.isSuccess,
    updateDomainMutation.isSuccess,
    deleteDomainMutation.isSuccess,
    updateFilterMutation.isSuccess,
    deleteFilterMutation.isSuccess,
    uploadMutation.isSuccess,
    updateConfigurationMutation.isSuccess,
    addCompetitorMutation.isSuccess,
    addDomainMutation.isSuccess,
    addQueryMutation.isSuccess,
    addFilterMutation.isSuccess,
    refetch,
  ]);

  const handleToggleCompetitor = (id: number) => {
    const competitor = data?.configurations.flatMap((config) => config.competitor_list).find((comp) => comp.competitor_id === id);
    if (competitor) {
      const updatedCompetitor = { ...competitor, active: !competitor.active };
      updateCompetitorMutation.mutate(updatedCompetitor);
    }
  };

  const handleToggleQuery = (id: number) => {
    const query = data?.configurations.flatMap((config) => config.query_list).find((q) => q.query_id === id);
    if (query) {
      const updatedQuery = { ...query, ignore: !query.ignore };
      updateQueryMutation.mutate(updatedQuery);
    }
  };

  const handleToggleViewCompetitor = (id: number) => {
    handleToggleCompetitor(id);
  };

  const handleToggleViewQuery = (id: number) => {
    handleToggleQuery(id);
  };

  const handleToggleOnOff = (id: number, column: keyof Query) => {
    const query = data?.configurations.flatMap((config) => config.query_list).find((q) => q.query_id === id);
    if (query) {
      const updatedQuery = { ...query, [column]: query[column] === 'ON' ? 'OFF' : 'ON' };
      updateQueryMutation.mutate(updatedQuery);
    }
  };

  const handleDeleteCompetitor = (competitorId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this competitor?");
    if (userConfirmed) {
      deleteCompetitorMutation.mutate(competitorId);
    }
  };

  const handleDeleteQuery = (queryId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this query?");
    if (userConfirmed) {
      deleteQueryMutation.mutate(queryId);
    }
  };

  const handleToggleDomain = (id: number) => {
    const domain = data?.configurations.flatMap((config) => config.our_domains_list).find((domain) => domain.domain_id === id);
    if (domain) {
      const updatedDomain = { ...domain, active: !domain.active };
      updateDomainMutation.mutate(updatedDomain);
    }
  };
  
  const handleDeleteDomain = (domainId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this domain?");
    if (userConfirmed) {
      deleteDomainMutation.mutate(domainId);
    }
  };
  
  const handleEditFilter = (filterId: number, level: string, stringVal: string) => {
    setEditedFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.filter_id === filterId ? { ...filter, level, string: stringVal } : filter
      )
    );
    setHasFilterChanges(true);
  };

  const handleEditCompetitor = (competitor: Competitor) => {
    setEditableCompetitor(competitor);
    setEditType('competitor');
    setIsEditing(true);
  };
  
  const handleEditDomain = (domain: Domain) => {
    setEditableDomain(domain);
    setEditType('domain');
    setIsEditing(true);
  };
  
  const handleEditQuery = (query: Query) => {
    setEditableQuery(query);
    setEditType('query');
    setIsEditing(true);
  };
  

  const handleSaveFilters = () => {
    const userConfirmed = window.confirm("Are you sure you want to save these changes?");
    
    if (userConfirmed) {
      editedFilters.forEach((filter) => {
        updateFilterMutation.mutate(filter);
      });
      setHasFilterChanges(false);
    }
  };

  const handleResetFilters = () => {
    setEditedFilters(initialFilters);
    setHasFilterChanges(false);
  };

  const handleDeleteFilter = (filterId: number) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this filter?");
    if (userConfirmed) {
      deleteFilterMutation.mutate(filterId);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setAutoOptimizeFileCFG(event.target.files[0]);
    }
  };

  const handleAddCompetitor = () => {
    setIsAdding(true);
    setNewCompetitor({ competitor_id: 0, domain: '', active: true, customer_id: customerId });
  };

  const handleAddDomain = () => {
    setIsAddingDomain(true);
    setNewDomain({ domain_id: 0, domain: '', active: true, customer_id: customerId });
  };

  const handleAddQuery = () => {
    setIsQuerying(true);
    setNewQuery({
      query_id: 0,
      account: '',
      campaign: '',
      ad_group: '',
      query: '',
      country: '',
      language: '',
      campaign_negative_matching: '',
      ignore: false,
      default: 'ON',
      current_status: 'ON',
      customer_id: customerId,
    });
  }

  const handleAddFilter = () => {
    setIsFiltering(true);
    setNewFilter({
      filter_id: 0,
      level: 'campaign.name',
      string: '',
      customer_id: customerId,
    });
  }

  const handleSaveNewQuery = () => {
    if (newQuery) {
      addQueryMutation.mutate(newQuery);
    }
  }

  const handleSaveNewCompetitor = () => {
    if (newCompetitor) {
      addCompetitorMutation.mutate(newCompetitor);
    }
  };

  const handleSaveNewDomain = () => {
    if (newDomain) {
      addDomainMutation.mutate(newDomain);
    }
  };

  const handleSaveNewFilter = () => {
    if (newFilter) {
      addFilterMutation.mutate(newFilter);
    }
  }

  const handleFileUpload = async () => {
    if (hasChanges || hasFilterChanges) {
      alert("You have unsaved changes. Please save your changes before uploading a file.");
      return; // Prevent file upload until changes are saved
    }

    if (autoOptimizeFileCFG) {
      const userConfirmed = window.confirm("Are you sure you want to upload this file?");
      
      if (userConfirmed) {
        await uploadMutation.mutateAsync({
          autoOptimizeFileCFG, customerId, dateFrom, dateTo,
        });
        setAutoOptimizeFileCFG(null);
        const fileInput = document.getElementById('autoOptimizeFileCFG') as HTMLInputElement | null;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    } else {
      alert("No file selected.");
    }
  };

  const handleDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdateFrom(event.target.value);
    setHasChanges(true);
  };

  const handleDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdateTo(event.target.value);
    setHasChanges(true);
  };

  const handleSaveDates = () => {
    const userConfirmed = window.confirm("Are you sure you want to save these changes?");
    
    if (userConfirmed && data) {
      
      const updatedConfig = {
        ...data.configurations[0],
        date_from: dateFrom,
        date_to: dateTo,
      };
  
      updateConfigurationMutation.mutate(updatedConfig);
    }
  };

  const handleSaveEdit = () => {
    if (editType === 'competitor' && editableCompetitor) {
      updateCompetitorMutation.mutate(editableCompetitor);
    } else if (editType === 'domain' && editableDomain) {
      updateDomainMutation.mutate(editableDomain);
    } else if (editType === 'query' && editableQuery) {
      updateQueryMutation.mutate(editableQuery);
    }
  
    setIsEditing(false);
  };

  // Function to reset values back to initial values
  const resetDatesToInitial = () => {
    setdateFrom(initialDateFrom);
    setdateTo(initialDateTo);
    setHasChanges(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Failed to fetch data</div>;
  }

  if (!data || !data.configurations) {
    return (
      enabled ? (
        <div className="bg-light border-t border-success">
          <h2 className="text-primarygray text-2xl font-bold mt-4">Auto Optimize</h2>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-bold">Add the prelim phase dates</div>
            <div className="flex items-center gap-2">
              <div className="text-sm font-medium">From:</div>
              <input
                onChange={handleDateFromChange}
                type="date"
                className="border border-gray200 rounded px-2 py-1"
                value={dateFrom}
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="text-sm font-medium">To:</div>
              <input
                onChange={handleDateToChange}
                type="date"
                className="border border-gray200 rounded px-2 py-1"
                value={dateTo}
              />
            </div>
          </div>
          <div className="flex items-center mt-6 grid-cols-2">
            <div className="border border-thirdgray rounded-md px-6">
              <label htmlFor="autoOptimizeFileCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
                {autoOptimizeFileCFG ? autoOptimizeFileCFG.name : `auto_optimize_${customerId}`}
              </label>
              <input
                type="file"
                id="autoOptimizeFileCFG"
                className="hidden"
                accept=".xlsx"
                onChange={(e) => {
                  handleFileChange(e);
                  setHasChanges(false);
                }}                
              />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
              alt="refresh-icon"
              className="ml-2 cursor-pointer"
              onClick={handleFileUpload}
            />
          </div>
        </div>
      ) : null
    );
  }

  const competitorData = data.configurations.flatMap((config) =>
    config.competitor_list.map((comp) => ({ ...comp, id: config.config_id, competitor_id: comp.competitor_id }))
  );

  const ourdomainData = data.configurations.flatMap((config) =>
    config.our_domains_list.map((domain) => ({ ...domain, id: config.config_id, domain_id: domain.domain_id }))
  );

  const queryData = data.configurations.flatMap((config) =>
    config.query_list.map((q) => ({ ...q, id: config.config_id, query_id: q.query_id }))
  );

  const handleExportAutoOptimize = (customerId: string) => {

    const url = `/export-auto-optimize/${customerId}/`;
  
    fetch(url, {
      method: 'GET',
    })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error('Failed to download the file');
      }
    })
    .then(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `auto_optimize_data_${customerId}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="bg-light border-t border-success">
      <h2 className="text-primarygray text-2xl font-bold mt-4">Auto Optimize</h2>
      <div className="flex items-center mt-6 grid-cols-2">
        <div className="border border-thirdgray rounded-md px-6">
          <label htmlFor="autoOptimizeFileCFG" className="cursor-pointer text-sm font-bold flex items-center text-thirdgray">
            {autoOptimizeFileCFG ? autoOptimizeFileCFG.name : `auto_optimize_${customerId}`}
          </label>
          <input
            type="file"
            id="autoOptimizeFileCFG"
            className="hidden"
            accept=".xlsx"
            onChange={handleFileChange}
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/static/img/download_green.svg`}
          alt="download_green-icon"
          className="ml-2 cursor-pointer"
          onClick={() => handleExportAutoOptimize(customerId)}
        />
        <img
          src={`${process.env.PUBLIC_URL}/static/img/refresh.svg`}
          alt="refresh-icon"
          className="ml-2 cursor-pointer"
          onClick={handleFileUpload}
        />
      </div>

      <div className="flex flex-col gap-2 mt-4">
        <div className="text-sm font-bold mb-2">Edit the prelim phase dates</div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <div className="text-sm font-medium">From:</div>
            <input
              onChange={handleDateFromChange}
              type="date"
              className="border border-gray200 rounded px-2 py-1 text-sm"
              value={dateFrom}
            />
          </div>

          <div className="flex items-center gap-2">
            <div className="text-sm font-medium">To:</div>
            <input
              onChange={handleDateToChange}
              type="date"
              className="border border-gray200 rounded px-2 py-1 text-sm"
              value={dateTo}
            />
          </div>

          {/* Save and Reset Buttons */}

          {hasChanges && (
            <button
              className="px-4 py-2 rounded-full bg-blue800 text-light"
              onClick={resetDatesToInitial}
            >
              Reset
            </button>
          )}
          <button
            className={`px-4 py-2 rounded-full ${hasChanges ? 'bg-success' : 'bg-gray200'} text-light`}
            onClick={handleSaveDates}
            disabled={!hasChanges}
          >
            Save
          </button>
        </div>
      </div>


      {editedFilters && editedFilters.length > 0 && (
        <>

          <div className="overflow-x-auto mt-4">
            <Table<Filter>
              data={editedFilters}
              columns={['level', 'string']}
              toggleView={undefined}
              onDelete={handleDeleteFilter}
              onAdd={handleAddFilter}
              onEdit={(filter) => handleEditFilter(filter.filter_id, filter.level, filter.string)}
              hideEditButton={true}
            />
          </div>

          <div className="flex items-center gap-4 mt-4 justify-end">
            <button
              className={`px-4 py-2 rounded-full ${hasFilterChanges ? 'bg-success' : 'bg-gray200'} text-light`}
              onClick={handleSaveFilters}
              disabled={!hasFilterChanges}
            >
              Save
            </button>
            {hasFilterChanges && (
              <button className="px-4 py-2 rounded-full bg-blue800 text-light" onClick={handleResetFilters}>
                Reset
              </button>
            )}
          </div>
        </>
      )}

      {isFiltering && newFilter && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96 grid grid-cols-2 gap-4">
            <h2 className="text-primarygray text-2xl font-bold col-span-2">Add New Filter</h2>
            <label className="block text-sm font-medium text-primarygray">Level</label>
            <select
              className="border border-gray200 rounded px-2 py-1 text-sm"
              value={newFilter.level}
              onChange={(e) => setNewFilter({ ...newFilter, level: e.target.value })}
            >
              <option value="campaign.name">Campaign Name</option>
              <option value="adGroup.name">AdGroup Name</option>
              <option value="customer.descriptive_name">Customer Descriptive Name</option>
            </select>
            <label className="block text-sm font-medium text-primarygray">String</label>
            <input
              type="text"
              className="border border-gray200 rounded px-2 py-1 text-sm"
              value={newFilter.string}
              onChange={(e) => setNewFilter({ ...newFilter, string: e.target.value })}
            />
            <div className="col-span-2 flex justify-end">
              <button
                className="mr-2 bg-thirdgray text-light px-4 py-2 rounded"
                onClick={() => setIsFiltering(false)}
              >
                Cancel
              </button>
              <button
                className="bg-success text-light px-4 py-2 rounded"
                onClick={() => handleSaveNewFilter()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {competitorData && competitorData.length > 0 && (
        <>
          <h2 className="text-primarygray text-sm font-bold mt-6">Competitor List</h2>
          <div className="overflow-x-auto mt-4">
            <Table<Competitor>
              data={competitorData}
              onAdd={handleAddCompetitor}
              columns={['domain', 'active']}
              toggleView={handleToggleViewCompetitor}
              onDelete={handleDeleteCompetitor}
              onEdit={handleEditCompetitor}
            />
          </div>
        </>
      )}

      {isAdding && newCompetitor && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Add New Competitor</h2>
            <div className="mt-4 flex items-center">
              <label className="block text-sm font-medium text-primarygray">Domain</label>
              <input
                type="text"
                className="p-1 ml-4 mt-1 block w-1/2 border border-gray200 rounded-md shadow-sm"
                value={newCompetitor.domain}
                onChange={(e) => setNewCompetitor({ ...newCompetitor, domain: e.target.value })}
              />
              <div className="ml-4">
                <label className="block text-sm font-medium text-primarygray">Active</label>
                <input
                  type="checkbox"
                  className="p-1 mt-1 block border border-gray200 rounded-md shadow-sm"
                  checked={newCompetitor.active}
                  onChange={(e) => setNewCompetitor({ ...newCompetitor, active: e.target.checked })}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsAdding(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveNewCompetitor}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {ourdomainData && ourdomainData.length > 0 && (
        <>
          <h2 className="text-primarygray text-sm font-bold mt-6">Our Domains List</h2>
          <div className="overflow-x-auto mt-4">
            <Table<Domain>
              data={ourdomainData}
              columns={['domain', 'active']}
              onAdd={handleAddDomain}
              toggleView={handleToggleDomain}
              onDelete={handleDeleteDomain}
              onEdit={handleEditDomain}
            />
          </div>
        </>
      )}

      {isAddingDomain && newDomain && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Add New Domain</h2>
            <div className="mt-4 flex items-center">
              <label className="block text-sm font-medium text-primarygray">Domain</label>
              <input
                type="text"
                className="p-1 ml-4 mt-1 block w-1/2 border border-gray200 rounded-md shadow-sm"
                value={newDomain.domain}
                onChange={(e) => setNewDomain({ ...newDomain, domain: e.target.value })}
              />
              <div className="ml-4">
                <label className="block text-sm font-medium text-primarygray">Active</label>
                <input
                  type="checkbox"
                  className="p-1 mt-1 block border border-gray200 rounded-md shadow-sm"
                  checked={newDomain.active}
                  onChange={(e) => setNewDomain({ ...newDomain, active: e.target.checked })}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsAddingDomain(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveNewDomain}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {queryData && queryData.length > 0 && (
        <> 
          <h2 className="text-primarygray text-sm font-bold mt-6">Exact Match Negative Keyword</h2>
          <div className="overflow-x-auto mt-4">
            <Table<Query>
              data={queryData}
              columns={['query', 'country', 'language', 'ignore', 'default', 'current_status']}
              onAdd={handleAddQuery}
              toggleView={handleToggleViewQuery}
              toggleOnOff={handleToggleOnOff}
              onDelete={handleDeleteQuery}
              onEdit={handleEditQuery}
            />
          </div>
        </>
      )}

      {isQuerying && newQuery && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">Add New Query</h2>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-primarygray">Account</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.account}
                  onChange={(e) => setNewQuery({ ...newQuery, account: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Campaign</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.campaign}
                  onChange={(e) => setNewQuery({ ...newQuery, campaign: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Ad Group</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.ad_group}
                  onChange={(e) => setNewQuery({ ...newQuery, ad_group: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Query</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.query}
                  onChange={(e) => setNewQuery({ ...newQuery, query: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Country</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.country}
                  onChange={(e) => setNewQuery({ ...newQuery, country: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Language</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.language}
                  onChange={(e) => setNewQuery({ ...newQuery, language: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Campaign Negative Matching</label>
                <input
                  type="text"
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.campaign_negative_matching}
                  onChange={(e) => setNewQuery({ ...newQuery, campaign_negative_matching: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-primarygray">Current Status</label>
                <select
                  className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                  value={newQuery.current_status}
                  onChange={(e) => setNewQuery({ ...newQuery, current_status: e.target.value })}
                >
                  <option value="ON">ON</option>
                  <option value="OFF">OFF</option>
                </select>
              </div>
              <div className="col-span-2 flex items-center">
                <label className="block text-sm font-medium text-primarygray">Ignore</label>
                <input
                  type="checkbox"
                  className="p-1 ml-4 mt-1 block border border-gray200 rounded-md shadow-sm"
                  checked={newQuery.ignore}
                  onChange={(e) => setNewQuery({ ...newQuery, ignore: e.target.checked })}
                />
                <label className="block text-sm ml-4 font-medium text-primarygray">Default</label>
                <input
                  type="checkbox"
                  className="p-1 ml-4 mt-1 block border border-gray200 rounded-md shadow-sm"
                  checked={newQuery.default === 'ON'}
                  onChange={(e) => setNewQuery({ ...newQuery, default: e.target.checked ? 'ON' : 'OFF' })}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsQuerying(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveNewQuery}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditing && (
        <div className="fixed inset-0 bg-gray600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-light p-4 rounded shadow-md w-1/2 overflow-auto max-h-96">
            <h2 className="text-primarygray text-2xl font-bold">
              Edit {editType === 'competitor' ? 'Competitor' : editType === 'domain' ? 'Domain' : 'Query'}
            </h2>
            <div className="mt-4">
              {editType === 'competitor' && editableCompetitor && (
                <>
                  <label className="block text-sm font-medium text-primarygray">Domain</label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={editableCompetitor.domain}
                    onChange={(e) => setEditableCompetitor({ ...editableCompetitor, domain: e.target.value })}
                  />
                  <label className="block text-sm font-medium text-primarygray">Active</label>
                  <input
                    type="checkbox"
                    className="mt-1 block"
                    checked={editableCompetitor.active}
                    onChange={(e) => setEditableCompetitor({ ...editableCompetitor, active: e.target.checked })}
                  />
                </>
              )}
              {editType === 'domain' && editableDomain && (
                <>
                  <label className="block text-sm font-medium text-primarygray">Domain</label>
                  <input
                    type="text"
                    className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                    value={editableDomain.domain}
                    onChange={(e) => setEditableDomain({ ...editableDomain, domain: e.target.value })}
                  />
                  <label className="block text-sm font-medium text-primarygray">Active</label>
                  <input
                    type="checkbox"
                    className="mt-1 block"
                    checked={editableDomain.active}
                    onChange={(e) => setEditableDomain({ ...editableDomain, active: e.target.checked })}
                  />
                </>
              )}
              {editType === 'query' && editableQuery && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-primarygray">Query</label>
                    <input
                      type="text"
                      className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                      value={editableQuery.query}
                      onChange={(e) => setEditableQuery({ ...editableQuery, query: e.target.value })}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-primarygray">Country</label>
                    <input
                      type="text"
                      className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                      value={editableQuery.country}
                      onChange={(e) => setEditableQuery({ ...editableQuery, country: e.target.value })}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-primarygray">Language</label>
                    <input
                      type="text"
                      className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                      value={editableQuery.language}
                      onChange={(e) => setEditableQuery({ ...editableQuery, language: e.target.value })}
                    />
                  </div>
                  {/* <div>
                    <label className="block text-sm font-medium text-primarygray">Current Status</label>
                    <select
                      className="p-1 mt-1 block w-full border border-gray200 rounded-md shadow-sm"
                      value={editableQuery.current_status}
                      onChange={(e) => setEditableQuery({ ...editableQuery, current_status: e.target.value })}
                    >
                      <option value="ON">ON</option>
                      <option value="OFF">OFF</option>
                    </select>
                  </div> */}
                  <div className="col-span-2">
                    <div className="flex items-center">
                      <label className="block text-sm font-medium text-primarygray mr-4">Ignore</label>
                      <input
                        type="checkbox"
                        className="mt-1 block"
                        checked={editableQuery.ignore}
                        onChange={(e) => setEditableQuery({ ...editableQuery, ignore: e.target.checked })}
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="block text-sm font-medium text-primarygray mr-4">Default</label>
                      <input
                        type="checkbox"
                        className="mt-1 block"
                        checked={editableQuery.default === 'ON'}
                        onChange={(e) => setEditableQuery({ ...editableQuery, default: e.target.checked ? 'ON' : 'OFF' })}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <button className="mr-2 bg-thirdgray text-light px-4 py-2 rounded" onClick={() => setIsEditing(false)}>
                Cancel
              </button>
              <button className="bg-success text-light px-4 py-2 rounded" onClick={handleSaveEdit}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default AutoOptimize;