import axios from 'axios';
import moment from 'moment';

// TYPES
import {
  StudyItem,
  StudyData,
  CompIntelReportType,
  GETCompIntelStudyList,
  CompIntelReportParams,
} from '../../types';
import { DateValueType } from 'react-tailwindcss-datepicker';

// UTILS
import { saveDownloadFile } from 'utils/download';

export const fetchStudiesData = async (): Promise<{
  studyList: StudyItem[];
  domain: string;
}> => {
  const response = await fetch('/study-list/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const compGroupData: StudyData = await response.json();
  if (compGroupData.study_list.length === 0 || !compGroupData.domain) {
    return { studyList: [], domain: '' };
  }

  const studyList = compGroupData.study_list;
  const domain = compGroupData.domain;

  return { studyList, domain };
};

export const fetchStudyList = async () => {
  const { studyList } = await fetchStudiesData();

  const { study_name } = studyList[0];

  const { data } = await axios.get<GETCompIntelStudyList>(
    '/competitive-intelligence-list/'
  );

  for (const { configurations } of data) {
    for (const config of configurations) {
      if (config.study_name === study_name) {
        return configurations;
      }
    }
  }
};

const validateDateRange = (
  dateRange: DateValueType,
  reportType: CompIntelReportType
) => {
  const startDate = dateRange?.startDate ? new Date(dateRange.startDate) : null;
  const endDate = dateRange?.endDate ? new Date(dateRange.endDate) : null;

  if (!dateRange) {
    throw new Error('Start and end date required.');
  }

  if (!startDate) {
    throw new Error('Start date required.');
  }

  if (!endDate) {
    throw new Error('End date is required');
  }

  if (reportType === 'content') {
    const max30Days = new Date(startDate);
    max30Days.setDate(max30Days.getDate() + 30);

    if (endDate > max30Days) {
      throw new Error('Maximum of 30 days for content report.');
    }
  }
  return true;
};

export const downloadCompIntelReport = async (
  params: CompIntelReportParams
) => {
  const { studyName, reportType, dateRange, fileType = 'xlsx' } = params;

  validateDateRange(dateRange, reportType);

  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');

  const url = `/get_comp_report/${reportType}/${fileType}/${startDate}/${endDate}`;

  const res = await axios.post<Blob>(url, {}, { responseType: 'blob' });

  const fileName = `${studyName}_${reportType}_${startDate}_thru_${endDate}`;
  saveDownloadFile(res, fileName);
};
