import { PropsWithChildren } from 'react';

interface ActionBarProps extends PropsWithChildren {
  subText?: string;
  topPos?: string | number;
}

function FilterBarWrapper({
  children,
  subText,
  topPos = 'top-[152px]',
}: ActionBarProps) {
  return (
    <>
      <div className={`sticky ${topPos} z-[2] bg-body pt-2`}>
        <div className="flex flex-wrap gap-4 items-center bg-light border text-sm rounded-2xl px-4 py-2">
          {children}
        </div>
        <div className="w-[99%] mx-auto border-b border-[#D3D3D3] mt-2" />
      </div>
      <div>
        {subText ? (
          <div className="flex justify-end mr-4 text-xs text-gray300 font-normal">
            {subText}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default FilterBarWrapper;
