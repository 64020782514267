import moment from 'moment';
import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';

// COMPONENTS
import TopAds from './CAComponents/TopAds';
import LoadingSpinner from '../LoadingSpinner';
import FilterBarWrapper from '../FilterBarWrapper';
import Top100Words from './CAComponents/Top100Words';
import DatepickerComponent from '../DatepickerComponent';
import { DateValueType } from 'react-tailwindcss-datepicker';
import TopWordsInAdCopy from './CAComponents/TopWordsInAdCopy';
import TimeFramePicker, { TimeFrameDateRange } from '../TimeFramePicker';
import CANewAdsSeenLast7Days from './CAComponents/CANewAdsSeenLast7Days';

import MultiDropdown from '../MultiDropdown';
import { useOutletContext } from 'react-router-dom';

// New Ads Seen Last 7 Days
interface PlatformAds {
  [website: string]: number;
}
interface AdMethod {
  desktop: PlatformAds;
  mobile: PlatformAds;
}
export interface NewAds {
  SEM: AdMethod;
  SEO: AdMethod;
  PLA: AdMethod;
}

// Top 10 Words
interface WordData {
  [word: string]: number;
}
interface NGramData {
  [ngram: string]: WordData;
}
export interface Top10Words {
  [website: string]: NGramData;
}

// Top 100 Words
export interface Top100WordsInterface {
  [ngram: string]: WordData;
}

// Top Ads
export interface Ad {
  brand: string;
  title: string;
  description: string | null;
  url: string;
  ad_type: 'SEO' | 'PLA' | 'SEM';
  times_seen: number;
  min_impression_share: number;
  max_impression_share: number;
  avg_impression_share: number;
  min_position: number;
  max_position: number;
  avg_position: number;
  search_terms: number;
}

type TopAdsInnerData = {
  [ad_type: string]: Ad[];
};

export interface AdsData {
  data: TopAdsInnerData;
}

interface CompetitorGroup {
  group_id: number;
  group_name: string;
  domains: string;
}

interface CompetitiveIntelligenceData {
  competitor_groups: CompetitorGroup[];
}

const fetchCompetitorGroups = async (): Promise<{
  competitorGroups: CompetitorGroup[];
  allDomains: string[];
}> => {
  const response = await fetch('/competitor-groups-list/');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const compGroupData: CompetitiveIntelligenceData = await response.json();
  if (compGroupData.competitor_groups.length === 0) {
    return { competitorGroups: [], allDomains: [] };
  }
  const competitorGroups = compGroupData.competitor_groups;
  const allDomains = competitorGroups
    .map((group) => group.domains.split(','))
    .flat();

  return { competitorGroups, allDomains };
};

// FETCH COMP INTEL CONTENT ANALYSIS DATA
const fetchCompData = async (dateRange: TimeFrameDateRange | null) => {
  if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
    return;
  }
  const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
  const response = await fetch(
    `/get_comp_data/content_analysis/${startDate}/${endDate}`
  );
  // const response = await fetch(`/get_comp_data/content_analysis/2024-06-05/2024-06-12`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

function CompIntelContentAnalysis() {
  const clientDomain = useOutletContext<string>();
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });

  const [competitorGroupOptions, setCompetitorGroupOptions] = useState<
    CompetitorGroup[]
  >([]);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState<string[]>(
    []
  );
  const [competitorOptions, setCompetitorOptions] = useState<string[]>([]);
  const [preselectedCompetitors, setPreselectedCompetitors] = useState<
    string[]
  >([]);

  const defaultDataStructure: DataStructure = {
    new_ads: {
      SEM: {
        desktop: {},
        mobile: {},
      },
      SEO: {
        desktop: {},
        mobile: {},
      },
      PLA: {
        desktop: {},
        mobile: {},
      },
    },
    top_100_words: {},
    top_10_words: {},
    top_ads: {
      ORGANIC: [],
      PAID: [],
      PLA: [],
    },
  };
  const [filteredData, setFilteredData] =
    useState<DataStructure>(defaultDataStructure);

  const { data: compGroupsData, isLoading: isLoadingCompGroups } = useQuery(
    ['competitorGroupsData'],
    () => fetchCompetitorGroups(),
    {}
  );

  const { data: compData, isLoading: isLoadingCompData } = useQuery(
    ['compDataContentAnalysis', dateRange?.startDate, dateRange?.endDate],
    () => fetchCompData(dateRange),
    { enabled: !!dateRange }
  );

  useEffect(() => {
    if (
      compGroupsData &&
      !isLoadingCompGroups &&
      compData &&
      !isLoadingCompData
    ) {
      const { competitorGroups, allDomains } = compGroupsData;

      setCompetitorGroupOptions(competitorGroups);
      setCompetitorOptions([...allDomains]);
      if (preselectedCompetitors.length === 0) {
        setPreselectedCompetitors([...allDomains]);
      } else if (!preselectedCompetitors.includes(clientDomain)) {
        setPreselectedCompetitors([clientDomain, ...allDomains]);
      } else if (
        preselectedCompetitors.includes(clientDomain) &&
        preselectedCompetitors.length === 1
      ) {
        setPreselectedCompetitors([...allDomains]);
      }

      if (clientDomain.length === 0 && compData) {
        const topCompetitors = getTopCompetitorsByCoverage(compData, 10);
        setPreselectedCompetitors(topCompetitors);
      }

      const initialSelectedGroups = competitorGroups.map(
        (group) => `${group.group_name}`
      );
      setSelectedGroupOptions(initialSelectedGroups);
    }
  }, [
    compGroupsData,
    isLoadingCompGroups,
    compData,
    isLoadingCompData,
    clientDomain,
  ]);

  // Function to get the top N competitors by number of ads
  const getTopCompetitorsByCoverage = (
    data: DataStructure,
    limit: number
  ): string[] => {
    const allCompetitors = Object.keys(data.new_ads.SEM.desktop);

    const sortedCompetitors = allCompetitors
      .map((competitor) => {
        const totalAds = data.new_ads.SEM.desktop[competitor];
        return { competitor, totalAds };
      })
      .sort((a, b) => b.totalAds - a.totalAds)
      .slice(0, limit)
      .map(({ competitor }) => competitor);

    return sortedCompetitors;
  };

  useEffect(() => {
    if (clientDomain && !preselectedCompetitors.includes(clientDomain)) {
      setPreselectedCompetitors((prev) => [...prev, clientDomain]);
    }
  }, [clientDomain, preselectedCompetitors]);

  const handleCompetitorGroupApply = (groups: string[]) => {
    let selectedCompetitors: string[] = [];
    let selectedGroups: string[] = [];

    groups.forEach((group_name) => {
      const matchedGroup = competitorGroupOptions.find(
        (g) => g.group_name === group_name
      );
      if (matchedGroup) {
        selectedCompetitors = [
          ...selectedCompetitors,
          ...matchedGroup.domains.split(','),
        ];
        selectedGroups = [...selectedGroups, ...[group_name]];
      }
    });

    setPreselectedCompetitors(selectedCompetitors);
    setSelectedGroupOptions(selectedGroups);
  };

  const { data, error, isLoading } = useQuery(
    ['compDataContentAnalysis', dateRange?.startDate, dateRange?.endDate],
    () => fetchCompData(dateRange)
  );

  useEffect(() => {
    setCompetitorOptions(
      Array.from(
        new Set([
          ...(data?.unique_brands || []),
          ...(compGroupsData?.allDomains || []),
        ])
      )
    );
  }, [data, compGroupsData]);

  useEffect(() => {
    if (preselectedCompetitors.length > 0) {
      if (data) {
        const newData = filterDataBasedOnDropdown(data, preselectedCompetitors);
        setFilteredData(newData);
      }
    } else {
      setFilteredData(defaultDataStructure);
    }
  }, [data, preselectedCompetitors]);

  // HANDLE TIME FRAME SELECTION
  const onChangeTimeFrame = (timeFrame: TimeFrameDateRange) => {
    setDateRange(timeFrame);
  };

  // HANDLE DATE PICKER SELECTIONS
  const onChangeDatePicker = (newValue: DateValueType) => {
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        startDate: new Date(newValue.startDate),
        endDate: new Date(newValue.endDate),
      });
    }
  };

  // -------------------- DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  type DataStructure = {
    new_ads: NewAds;
    top_100_words: Top100WordsInterface;
    top_10_words: Top10Words;
    top_ads: TopAdsInnerData;
  };

  const filterNewAds = (data: PlatformAds, selectedDomains: string[]) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as PlatformAds);
  };

  const filterTop100Words = (
    data: Top100WordsInterface,
    selectedDomains: string[]
  ) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as Top100WordsInterface);
  };

  const filterTop10Words = (data: Top10Words, selectedDomains: string[]) => {
    return Object.keys(data)
      .filter((domain) => selectedDomains.includes(domain))
      .reduce((acc, domain) => {
        acc[domain] = data[domain];
        return acc;
      }, {} as Top10Words);
  };

  const filterTopAds = (data: Ad[], selectedDomains: string[]) => {
    return data.filter((ad) => selectedDomains.includes(ad.brand));
  };

  const filterDataBasedOnDropdown = (
    data: DataStructure,
    selectedDomains: string[]
  ): DataStructure => {
    if (selectedDomains.length === 0) return defaultDataStructure;

    const newData: DataStructure = {
      new_ads: {
        SEM: {
          desktop: {},
          mobile: {},
        },
        SEO: {
          desktop: {},
          mobile: {},
        },
        PLA: {
          desktop: {},
          mobile: {},
        },
      },
      top_100_words: {},
      top_10_words: {},
      top_ads: {
        ORGANIC: [],
        PAID: [],
        PLA: [],
      },
    };

    // Filter new ads data with nullish coalescing and optional chaining
    newData.new_ads = {
      SEM: {
        desktop: filterNewAds(
          data?.new_ads?.SEM?.desktop ?? {},
          selectedDomains
        ),
        mobile: filterNewAds(data?.new_ads?.SEM?.mobile ?? {}, selectedDomains),
      },
      SEO: {
        desktop: filterNewAds(
          data?.new_ads?.SEO?.desktop ?? {},
          selectedDomains
        ),
        mobile: filterNewAds(data?.new_ads?.SEO?.mobile ?? {}, selectedDomains),
      },
      PLA: {
        desktop: filterNewAds(
          data?.new_ads?.PLA?.desktop ?? {},
          selectedDomains
        ),
        mobile: filterNewAds(data?.new_ads?.PLA?.mobile ?? {}, selectedDomains),
      },
    };

    // Filter top 100 words data with nullish coalescing
    newData.top_100_words = filterTop100Words(
      data?.top_100_words ?? {},
      selectedDomains
    );

    // Filter top 10 words data with nullish coalescing
    newData.top_10_words = filterTop10Words(
      data?.top_10_words ?? {},
      selectedDomains
    );

    // Filter top ads data with nullish coalescing
    newData.top_ads = {
      ORGANIC: filterTopAds(data?.top_ads?.ORGANIC ?? [], selectedDomains),
      PAID: filterTopAds(data?.top_ads?.PAID ?? [], selectedDomains),
      PLA: filterTopAds(data?.top_ads?.PLA ?? [], selectedDomains),
    };

    return newData;
  };

  // -------------------- END OF: DATA FILTERING BASED ON DROPDOWN SECTION --------------------

  // JSX
  return (
    <div className="py-12">
      <FilterBarWrapper>
        <TimeFramePicker onChange={onChangeTimeFrame} defaultValue="1M" />
        <div className="flex-1" />
        <div className="flex gap-4 mt-2 md:mt-0">
          <MultiDropdown
            placeholder={'Competitor Groups'}
            options={competitorGroupOptions.map(
              (group) => `${group.group_name}`
            )}
            preselectedOptions={selectedGroupOptions}
            // onOptionClick={handleCompetitorChange}
            onSelectionChange={handleCompetitorGroupApply}
            dropdownLength="max-h-60"
            dropdownOpenWidth="max-w-80"
          />
          <MultiDropdown
            placeholder={'Domains'}
            options={competitorOptions}
            preselectedOptions={preselectedCompetitors}
            // onOptionClick={handleCompetitorChange}
            onSelectionChange={setPreselectedCompetitors}
            dropdownLength="max-h-60"
            dropdownOpenWidth="max-w-80"
          />
          {/* <Dropdown title='Competitor' options={['competitor 1', 'competitor 2', 'competitor 3']} mode='light' onOptionClick={() => {}}/> */}
          <DatepickerComponent
            value={dateRange}
            onChange={onChangeDatePicker}
            product="comp_int"
          />
        </div>
      </FilterBarWrapper>
      <div className="flex justify-end mr-4 text-xs text-gray300 font-normal">
        Data updates daily
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>Error fetching data</div>
      ) : data ? (
        <>
          {/* <CANewAdsSeenLast7Days data={data.new_ads} /> */}
          {filteredData && filteredData.new_ads ? (
            <CANewAdsSeenLast7Days data={filteredData.new_ads} />
          ) : (
            <LoadingSpinner />
          )}

          {/* <TopWordsInAdCopy data={data.top_10_words} /> */}
          {filteredData && filteredData.top_10_words ? (
            <TopWordsInAdCopy
              data={filteredData.top_10_words}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          {/* <Top100Words data={data.top_100_words} /> */}
          {filteredData && filteredData.top_100_words ? (
            <Top100Words
              data={filteredData.top_100_words}
              clientDomain={clientDomain}
            />
          ) : (
            <LoadingSpinner />
          )}

          {/* <TopAds data={data.top_ads} /> */}
          {filteredData && filteredData.top_ads ? (
            <TopAds data={filteredData.top_ads} />
          ) : (
            <LoadingSpinner />
          )}
        </>
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}

export default CompIntelContentAnalysis;
